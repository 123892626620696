<template>
    <div v-if="!course.isHidden" class="card-container" :class="{ 'col-sm-auto': true, 'for-slider': forSlider, /**fromManager, 'col-md-6 col-lg-4 col-12': !fromManager,**/ 'is-application': isApplication, 'is-path': isPath, }">
        <div v-if="isVoid" class="card invisible"></div>
        <div v-else class="card h-100 position-relative mx-auto p-0">
            <a :href="getUrlCourse(course)" class="cards-lc" :class="{invisible: course.needsConfirmation }">
                <div class="img-wrapper">
                  <template v-if="imageAvailable(course.course_image)" >
                    <b-img-lazy 
                      :src="'index.php?r=course/getFile&courseid='+course.id+'&file='+course.course_image"
                      :placeholder="'index.php?r=course/getFile&courseid='+course.id+'&file='+course.course_image"
                      :alt="course.name"
                      :id="'img-alt-'+course.id_course"
                      class="card-img-top"
                      style="display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
                    ></b-img-lazy>
                    <b-tooltip class="tooltip1" :target="'img-alt-'+course.id_course" placement="right">
                        {{ course.course_image_alt_text || course.name }}
                    </b-tooltip>
                  </template>
                    <!--
                    <img class="card-img-top" v-if="imageAvailable(course.course_image)"
                        :src="'index.php?r=course/getFile&courseid='+course.id+'&file='+course.course_image" 
                        :alt="course.name" 
                        @error="course.image=null;" 
                        style="display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                    -->
                   <canvas v-else class="canvas-image" ref="canvasImage" :alt="course.name" :id="'img-alt-'+course.id_course"></canvas>
                   <b-tooltip class="tooltip1" :target="'img-alt-'+course.id_course" placement="right">
                        {{ course.course_image_alt_text || course.name }}
                    </b-tooltip>
                    <div v-if="showStartDate && !isApplication"
                        class="date-area position-absolute">
                        <div class="month">
                            <span>{{ mergeDateTime(course.course_start_date, course.course_start_hour) | formatShortMonth(timezoneHelper(course)) }}</span>
                        </div>
                        <div class="day">
                            <span>{{ mergeDateTime(course.course_start_date, course.course_start_hour) | formatDay(timezoneHelper(course)) }}</span>
                        </div>
                        <div v-if="course.course_start_hour && course.course_start_hour!='00:00:00'" class="card-hour">
                            <span>{{ mergeDateTime(course.course_start_date, course.course_start_hour) | formatTime(timezoneHelper(course)) }}</span>
                        </div>
                        <div v-else>&nbsp;</div>
                        
                    </div>
                    <CourseFlags :course="course" />
                </div>

                <div v-if="isApplication" class="card-body">
                    <div class="card-content">
                        <p v-if="showStartDate"><span class="app-available">{{ t('Available:') }}</span> {{ course.course_start_date | formatAppDate(timezoneHelper(course)) }}<span v-if="showEndDate">&nbsp;{{ t('to') }}&nbsp;{{ course.course_end_date | formatAppDate(timezoneHelper(course)) }}</span></p>
                        <p class="app-name">{{ course.name }}</p>
                        <p class="app-title">{{ t('Application') }}</p>
                    </div>

                    <div class="d-flex align-items-end justify-content-between card-foot">
                        <HeartReactionIcon :borderColor="'#FFFFFF'" :fillColor="'#FFFFFF'" :course="course" :isSmall="true" />
                        <!-- space tag -->
                        <div></div>
                    </div>
                </div>

                <div v-else-if="isPath" class="card-body">
                    <div class="card-content">
                        <p class="app-name">{{ course.name }}</p>
                        <p class="app-title">{{ t('Path') }}</p>
                    </div>

                    <div class="d-flex align-items-end justify-content-between card-foot">
                        <HeartReactionIcon :borderColor="'#FFFFFF'" :fillColor="'#FFFFFF'" :course="course" :isSmall="true" />
                        <!-- space tag -->
                        <!--label class="price-label">{{ course.pathways||0 }}&nbsp;{{ t('EVENTS') }}&nbsp;|&nbsp;{{ 0 }}&nbsp;{{ t('REWARD') }}</label-->
                        <label class="price-label">{{ course.pathways||0 }}&nbsp;{{ t('EVENTS') }}</label>
                    </div>
                </div>

                <div v-else class="card-body position-relative">
                    <div class="title-wrap p-3">
                        <template  v-if="course.course_type === 'online'">
                            <online-icon></online-icon>
                            <span class="online-label">{{t('ONLINE EVENT')}}</span>
                        </template>
                        <template  v-if="course.course_type === 'onsite'">
                            <onsite-icon></onsite-icon>
                            <span class="online-label">{{t('ONSITE EVENT')}}</span>
                            <span class="online-label" v-if="!!locationCity">|</span>
                            <span class="address-label">{{locationCity}}</span>
                        </template>
                        <template  v-if="course.course_type === 'blended'">
                            <blended-icon></blended-icon>
                            <span class="online-label">{{t('BLENDED EVENT')}}</span>
                        </template>
                        <a :href="getUrlCourse(course)" class="clean">
                            <h5 class="card-title"
                                :style="{color: colorFilter}"
                                style="display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
                                    overflow: hidden; text-overflow: ellipsis;">
                                {{course.name}} 
                            </h5>
                            <p class="card-type">{{ t('Event') }}</p>
                        </a>
                    </div>
                    
                    <div class="d-flex align-items-end justify-content-between card-foot" :class="{'card-text': !freeSeats(course.price, course.highest_price)}">
                        <HeartReactionIcon :course="course" :isSmall="true" />
                        <!-- Free flag -->
                         <template v-if="freeSeats(course.price, course.highest_price)">
                            <div v-if="!corporationInfo.hide_free" class="card-text-free">{{t('FREE')}}</div>
                         </template>
                        <!-- Range of prices -->
                        <label v-else class="price-label">
                            {{getRangeOfPrices(course.price, course.highest_price)}}
                        </label>
                        
                    </div>

                    <!-- <div v-if ="course.price!='FREE'" class="card-text" style="text-align:right;">
                        <label class="price-label px-3">
                            {{removeCurrency(course.price)}}
                        </label>
                    </div>
                    <div v-else-if="course.price=='FREE'" class="card-text-free">
                        {{t(course.price)}}
                    </div> -->
                </div>

                <!-- <div class="card-footer">
                
                    <b-button type="button"
                            style="background-color:#256AB3; border-color:#256AB3;"
                            v-if="showViewCourse"
                            @click="gotoLanding(course)"
                            class="btn btn-light enroll viewcourse">
                        <svg width="16" height="18" xmlns="http://www.w3.org/2000/svg" class="mr-1" style="vertical-align: sub;">
                            <path d="M2.583 6.917H.917A.417.417 0 0 1 .5 6.5V4.833c0-.23.187-.416.417-.416H5.5v-2.5c0-.69.56-1.25 1.25-1.25h6.667c.69 0 1.25.56 1.25 1.25v5.675a.42.42 0 0 1 .021.07l.803 3.75c.007.033.01.065.009.097V14h-.833v3.333h-2.5V14H8v3.333H5.5V14h-.833v-2.491c-.001-.033.002-.066.01-.1l.183-.824c-1.303-.05-2.105-.552-2.277-1.585V6.917zm.833 0l-.005 2.012c.092.536.585.82 1.633.826l.456-2.05v-.788H3.416zm2.917 0v.416h7.5V1.917a.417.417 0 0 0-.416-.417H6.75a.417.417 0 0 0-.417.417v2.5h2.084c.23 0 .416.186.416.416V6.5c0 .23-.186.417-.416.417H6.333zm-.082 1.25L5.51 11.5h9.146l-.713-3.333H6.251zM13.833 14H13v2.5h.833V14zm-1.666-.833h2.5v-.834H5.5v.834h6.667zM6.333 14v2.5h.834V14h-.834zm-5-8.75v.833H8V5.25H1.333z" fill="#FFF" fill-rule="evenodd"/>
                        </svg>
                        {{t('View Course')}}
                    </b-button>
                
                    <b-button type="button"
                            :style="corporationStyles"
                            v-if="showEnroll"
                            :disabled="isEnrollDisabled"
                            @click="$emit('enroll', course.id)"
                            class="btn btn-light enroll">
                        <i class="bg-add-participant mr-1"></i>
                        {{t('Enroll')}}
                    </b-button>
                    <b-button type="button"
                            v-else-if="showWaitList"
                            :disabled="isEnrollDisabled"
                            :style="corporationStyles"
                            @click="$emit('wait-list', course.id)"
                            class="btn btn-light enroll">
                        <wait-list-icon></wait-list-icon>
                        {{t('Waitlist')}}
                    </b-button>
                    <b-button type="button"
                            v-else-if="showWaitingWaitList"
                            
                            :style="corporationStyles"
                            @click="$emit('remove-from-wait-list', course.id)"
                            class="btn btn-light enroll">
                        <wait-list-icon></wait-list-icon>
                        {{t('Waiting')}}
                    </b-button>
                    <b-button v-else-if="showSoldOut"
                            type="button" class="btn btn-light full-btn" style="color:#ababab;">
                        <svg width="20" height="10" xmlns="http://www.w3.org/2000/svg" class="mb-1 mr-1">
                            <g id="Course-Templates" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Marketplace-GRID-simple" transform="translate(-104 -1918)" fill="#ABABAB"><g id="full2" transform="translate(104 1918)">
                            <path d="M18.154 0v2.42H20v4.838h-1.846v2.42H0V0h18.154zm-.923.907H.923V8.77h16.308V.907zM2.119 1.935l6.268 5.807H1.935V1.935h.184zm7.394 0l5.65 5.807h-5.65L3.245 1.935h6.268zm6.496 0l-.001 5.685-5.685-5.685h5.686zm3.068 1.392h-.923V6.35h.923V3.327z" id="Combined-Shape"/>
                            </g></g></g>
                        </svg>      
                        {{t('Full Course')}}
                    </b-button>
                    <b-button v-else-if="showProgram"
                            type="button" class="btn btn-light program">
                        <i class="bg-add-participant mr-1"></i>
                        {{t('View Program')}}
                    </b-button>
                    <div class="favorite-section">
                        <i :class="{'new-bg-wish-disabled': !course.has_like, 'new-bg-wish-active': course.has_like}"
                        @click="changeReaction(course)">
                        </i>
                    </div>
                </div>-->
        
            </a>
            <div v-if="course.needsConfirmation" class="change-react-cancel">
                <div class="cancel-message">
                    <TrashThinIcon :color="'#383838'" height="20" width="20" />
                    <p><strong>{{ course.name }}</strong>&nbsp;{{ t('has been deleted from Favorites') }}</p>
                </div><!-- .cancel-message -->
                <p class="text-center"><span class="pointer" @click.prevent="cancelConfirmation()">{{ t('Undo') }}</span>&nbsp;{{ currentTimer }}&nbsp;{{ t('seconds') }}</p>
            </div><!-- .change-react-cancel -->
        </div><!-- .card -->
    </div><!-- .card-container -->
</template>

<script>
import API from '@/api/index';
import _ from 'lodash';
import CourseFlags from '@/components/CourseFlags';
import WaitListIcon from '@/components/Icons/WaitListIcon';
import OnlineIcon from '@/components/Icons/OnlineIcon';
import OnsiteIcon from '@/components/Icons/OnsiteIcon';
import BlendedIcon from '@/components/Icons/BlendedIcon';
import EnrollBtnUtils from '@/mixins/EnrollBtnUtils';
import HeartReactionIcon from '@/components/Icons/HeartReactionIcon';
import TrashThinIcon from '@/components/Icons/TrashThinIcon';
import FormattingMixin from '@/mixins/FormattingMixin';
import { BImgLazy } from 'bootstrap-vue';
import { mapState, } from 'vuex';

export default {
    name: "CourseCard",
    mixins: [
        EnrollBtnUtils,
        FormattingMixin,
    ],
    props: {
        course: {
            type: Object
        },
        corporation: {
            type: Object
        },
        fromManager: {
            type: Boolean,
            default() {
                return false;
            },
        },
        isVoid: {
            type: Boolean,
            default() {
                return false;
            },
        },
        forSlider: {
            default() {
                return false;
            },
        },
    },
    data: function() {
        return {
            defaultTimer: 8,
            currentTimer: 0,
        };
    },
    components: {
        WaitListIcon,
        OnlineIcon,
        OnsiteIcon,
        BlendedIcon,
        HeartReactionIcon,
        TrashThinIcon,
        CourseFlags,
        BImgLazy
    },
    computed: {
        ...mapState(['corporationInfo', ]),
        corporationStyles() {
            let styles = {};
            const courseListColor = _.get(this.corporation, 'color_enroll_course_list', null);
            if (courseListColor) {
                styles.backgroundColor = courseListColor;
                styles.borderColor = courseListColor;
            }
            return styles;
        },
        colorFilter() {
            return _.get(this.corporation, 'color_filters', null);
        },
        locationCity() {
            return [_.get(this.course,'loc_name'), _.get(this.course, 'locations[0].loc_city', '')].filter(v=>v).join(', ');
        },
        isEnrollDisabled() {
            return this.course.id === 827;
        },
        showViewCourse(){
        	return this.showInCourse(this.course)
        },
        showSoldOut(){
            return this.showSoldOutFunc(this.course);
        },
        showWaitList(){
            return this.showWaitListFunc(this.course);
        },
        showWaitingWaitList(){
            return this.showWaitingFunc(this.course);
        },
        showEnroll(){
            return this.showEnrollFunc(this.course);
        },
        showComingSoon(){
            return this.showComingSoonFunc(this.course);
        },
        Loader(){
            return this.showLoader;
        },
        showStartDate() {
            return moment(this.course.course_start_date, 'YYYY-MM-DD').isValid();
        },
        showEndDate() {
            return moment(this.course.course_end_date, 'YYYY-MM-DD').isValid();
        },
        startMonth() {
            let month = moment(this.course.course_start_date, 'YYYY-MM-DD').format('MMM');
            month = month.replace("Jan", this.t("Jan"))
            month = month.replace("Apr", this.t("Apr"))
            month = month.replace("Aug", this.t("Aug"))
            month = month.replace("Dec", this.t("Dec"))
            return month
        },
        startDay() {
            return moment(this.course.course_start_date, 'YYYY-MM-DD').format('D');
        },
        isApplication() {
            return _.get(this.course, 'id_course_behavior', 0) === 1;
        },
        isPath() {
            return _.get(this.course, 'id_course_behavior', 0) === 2;
        },
    },
    methods: {
        imageAvailable(image){             
            if (image) {
                return true 
            }else{
                return false
            }
        },
        getRangeOfPrices(price, highest_price){
            price = price === 'FREE' || price === 0 ? price = this.t('FREE') : this.removeCurrency(price);
            highest_price = this.removeCurrency(highest_price);

            // console.log('Prices', price, highest_price);
            if(price === highest_price){
                return price
            }else{
                return `${price} - ${highest_price}`
            }
        },
        freeSeats(price, highest_price){
            price = price === 'FREE' || price === 0 ? price = 0 : price;
            highest_price = highest_price === 'FREE' || highest_price === 0 || highest_price === null ? highest_price = 0 : highest_price;

            if(price === highest_price){
                return true
            }else{
                return false
            }
        },
        changeReaction() {
            this.course.has_like = !this.course.has_like;
            API.main.saveReaction({
                id_course: this.course.id,
                has_like: this.course.has_like
            });
        },
        gotoLanding(course){
        
        	window.location.href = CATALOGUE_PATH? CATALOGUE_PATH+course.id  : course.url
        
        },
        getUrlCourse(course){
        	return (CATALOGUE_PATH ? CATALOGUE_PATH + course.id  : course.url) + (Object.keys(this.$route.params).length > 0 ? '#' + this.$route.path : '');
        },
        getHour(hour){
        	var format = hour.split(":")
          format.pop();
        	var hourNumber = format[0] > 12 ? format[0] - 12 : format[0];
          var pmam;
          
          // console.log("le format")
          // console.log(format[0])
          // console.log(format[0] >= 12)
          
          if (format[0] > 12) {
            pmam = "PM"
          }else if (format[0] < 12) {
            pmam = "AM"
          }else{
            pmam = "PM"
          }
          
          // console.log(pmam);
        	
        	return hourNumber+":"+format[1]+" "+pmam
        
        },
        removeCurrency(price){
            if(price){
                // let example = "$11.66 CAD"
                // console.log('Current price', price);
                if(typeof price === "string"){
                    let array = price.split(" ");
                    return array[0];
                }else{
                    return `$${price.toString()}`; //"$11.66"
                }
            }else{
                return ""
            }
        },
        getRandomColor() {
            // if randomColor cndjs is not available
            let letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16777216)];
            }
            return color;
        },
        startTimerCounting: function(duration) {
            var _this = this;
            var timer = duration;
            var _intervar = setInterval(function() {
                _this.formatTimerCounting(timer);
                if (--timer < 0 || !_.get(_this.course, 'needsConfirmation', false)) {//Time is over
                    //timer = duration;
                    clearInterval(_intervar);
                    if(_.get(_this.course, 'needsConfirmation', false)) {
                        _this.$set(_this.course, 'needsHide', true);
                    } else { //Was canceled
                    }
                }
            }, 1000);
        },
        formatTimerCounting: function(timer) {
            var minutes = parseInt(timer / 60, 10),
                seconds = parseInt(timer % 60, 10);
            //minutes = minutes < 10 ? "0" + minutes : minutes;
            //seconds = seconds < 10 ? "0" + seconds : seconds;
            //this.setTimerCounter(minutes + ":" + seconds);
            this.currentTimer = seconds;
        },
        cancelConfirmation: function() {
            this.$set(this.course, 'needsConfirmation', false);
        },
        canvasGradient() {
            if(this.$refs.canvasImage) {
                let a = typeof randomColor == 'function' ? randomColor() : this.getRandomColor();
                let b = typeof randomColor == 'function' ? randomColor() : this.getRandomColor();
                this.$refs.canvasImage.style.backgroundImage = `linear-gradient(to right, ${a}, ${b})`;
            }
        },
        imgError() {
            this.course.image = null;
            this.$nextTick(()=>this.canvasGradient());
        },
    },
    watch: {
        'course.needsConfirmation': function(val) {
            if(val && _.get(this.course, 'needsConfirmation', false) && this.course.has_like) {
                this.startTimerCounting(_.clone(this.defaultTimer, true) - 1);
            }
        },
    },
    created: function() {
        this.$set(this.course, 'fromManager', false);
        this.$set(this.course, 'needsConfirmation', false);
        this.$set(this.course, 'isHidden', this.fromManager && !this.course.has_like);
        this.$set(this.course, 'needsHide', false);
    },
    mounted: function() {
        this.canvasGradient();
        this.$set(this.course, 'fromManager', this.fromManager);
        if(this.course.fromManager) {
            this.formatTimerCounting(_.clone(this.defaultTimer, true));
        }
    }
}
</script>

<style scoped>
    .card-container {
        margin-top: 30px;
    }

    .card-container /deep/ .card {
        border: 1px solid rgb(227,229,232);
        border-radius: 4px;
        width: 280px;
        transition: all 0.5s cubic-bezier(.25,.8,.25,1);
    }
    .card-container /deep/ .card:hover {
    	box-shadow: 0px 2px 16px rgba(56,56,56,0.3);
    }
    .card-container /deep/ .card .card-body {
        padding: 0px;
        height: auto;
    }

    .card-container /deep/ .card .card-title {
        color: #383838;
        font-family: "Source Sans Pro";
        margin-bottom: 4px;
        font-weight: bold;
        font-size:18px;
    }
    
    .card-container /deep/ .card .card-type {
        color: rgb(56,56,56);
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    .card-container /deep/ .card .img-wrapper {
        background-color: #f6f6f6;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 126px;
        overflow: hidden;
        width: 280px;
    }

    .card-container /deep/ .card img.card-img-top {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .card-container /deep/ .card-text-free {
        color: rgb(255,255,255);
        background-image: linear-gradient(to top left, #59AE8C 50%, transparent 50%);
        border-bottom-right-radius: 4px;
        float: right;
        font-size: 12px;
        line-height: 16px;
        padding: 20px 17px 10px 94px;
        position: relative;
        text-align: right;
    }
    /*.card-container /deep/ .card .card-footer {
        height: 36px;
        padding: 0;
    }

    .card-container /deep/ .card-footer .favorite-section {
        width: 50px;
        float: right;
        height: 35px;
        padding-top: 8px;
        padding-left: 15px;
        cursor: pointer;
        background-color: white;
    }

    .card-container /deep/ .card-footer button {
        width: calc(100% - 50px);
        text-align: left !important;
        font-size: 18px !important;
        font-family: "Source Sans Pro";
        line-height: 22px;
        border-radius: 0 0 0 4px !important;
    }

    .card-container /deep/ .card-footer button:hover {
        opacity: .5;
    }

    .card-container /deep/ .card-footer button.disabled:hover {
        opacity: initial !important;
    }

    .card-container /deep/ .card-footer button.enroll:active,
    .card-container /deep/ .card-footer button.enroll {
        background-color: rgb(0, 174, 239);
        border-color: rgb(0, 174, 239);
        color: white;
    }

    .card-container /deep/ .card-footer button.program:active,
    .card-container /deep/ .card-footer button.program {
        background-color: #F4981F;
        border-color: #F4981F;
        color: white;
    }

    .card-container /deep/ .card-footer button.full-btn:active,
    .card-container /deep/ .card-footer button.full-btn {
        background-color: transparent;
        border-color: #e9e9e9;
        color: #383838;
    }*/
    
    .card-container /deep/ .card-body .price-label {
        color: rgb(56,56,56);
        font-family: "Source Sans Pro";
        font-size: 20px;
        line-height: 26px;
        margin: 0 12px 8px 0;
        text-align: right;
    }
    .card-container /deep/ .card-body .heart-reaction-icon-container {
        float: left;
        margin: 0 0 16px 18px;
    }
    .card-container /deep/ .card-body .title-wrap {
    }
    .card-container /deep/ .new-attention-wrapper {
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-weight: 600;
        top: 70px;
        right: -8px;
    }
    
    .card-container /deep/ a.cards-lc:not(.md-button):hover {
        text-decoration:none;
    }
    .card-container /deep/ .new-attention-wrapper .text {
        position: relative;
        text-align: center;
        padding-top: 10px;
    }

    .card-container /deep/ .new-attention-wrapper .text.white > span {
        color: white;
        right: 10px;
    }

    .card-container /deep/ .new-attention-wrapper .text > span {
        position: absolute;
        top: 13px;
        right: 15px;
        color: #383838;
    }

    .card-container /deep/ .date-area {
        background-color: #FFFFFF;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        left: 0;
        top: 0;
    }

    .card-container /deep/ .date-area .month {
        background-color: #D95A4B;
        border-top-left-radius: 4px;
        color: #FFFFFF;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-weight: 600;
        height: 18px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        width: 60px;
    }

    .card-container /deep/ .date-area .day {
        background-color: #FFFFFF;
        border-bottom-right-radius: 4px;
        height: 32px;
        text-align: center;
        width: 60px;
    }

    .card-container /deep/ .date-area .day span {
        color: #383838;
        font-family: "Source Sans Pro";
        font-size: 25px;
        line-height: 32px;
    }
    .card-container /deep/ .date-area .card-hour {
        background-color: #FFFFFF;
        border-bottom-right-radius: 4px;
        height: 24px;
        text-align: center;
        width: 60px;
    }
    .card-container /deep/ .date-area .card-hour span {
        color: #383838;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
    }
    .card-container /deep/ .card span.address-label,
    .card-container /deep/ .card span.online-label {
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ababab;
        text-transform: uppercase;
    }

    .card-container /deep/ .card a.clean,
    .card-container /deep/ .card a.clean:hover {
        text-decoration: none !important;
    }
    .card-container /deep/ .canvas-image {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .card-container /deep/ .change-react-cancel {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
    }
    .card-container /deep/ .change-react-cancel .cancel-message {
        align-items: center;
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 12px;
        margin: 0 24px 24px;
    }
    .card-container /deep/ .change-react-cancel p {
        color: rgb(56,56,56);
        font-family: "Source Sans Pro";
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
    }
    .card-container /deep/ .change-react-cancel p span {
        color: rgb(37,106,179);
    }
    .card-container /deep/ .change-react-cancel p span.pointer {
        cursor: pointer;
    }
    .card-container.is-application {}
    .card-container.is-application /deep/ .card {
        background-color: rgb(231,106,32);
    }
    .card-container.is-path /deep/ .card {
        background-color: rgb(0, 174, 239);
    }
    .card-container.is-application /deep/ .card-body {}
    .card-container.is-application /deep/ .card-body .card-content,
    .card-container.is-path /deep/ .card-body .card-content {
        padding: 16px;
    }
    .card-container.is-application /deep/ .card-body .card-content,
    .card-container.is-path /deep/ .card-body .card-content,
    .card-container /deep/ .card-body .title-wrap {
        min-height: 150px;
    }
    .card-container.is-application /deep/ .card-body p,
    .card-container.is-path /deep/ .card-body p {
        color: rgb(255,255,255);
        font-family: "Source Sans Pro";
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
    }
    .card-container.is-application /deep/ .card-body p.app-name,
    .card-container.is-path /deep/ .card-body p.app-name {
        font-size: 18px;
        font-weight: bolder;
        line-height: 22px;
        margin-top: 6px;
        margin-bottom: 4px;
    }
    .card-container.is-application /deep/ .card-body p.app-title,
    .card-container.is-path /deep/ .card-body p.app-title {
        font-size: 12px;
        font-weight: bolder;
        line-height: 16px;
        margin-top: 4px;
        text-transform: uppercase;
    }
    .card-container.is-application /deep/ .card-body .app-available {
        text-transform: uppercase;
    }

    .card-container.is-path /deep/ .card-body .price-label {
        color: rgb(255,255,255);
        font-size: 18px;
        line-height: 22px;
    }
    .card-container /deep/ .card-foot {
        min-height: 46px;
    }
    .card-container /deep/ .card,
    .card-container /deep/ .card .img-wrapper,
    .card-container /deep/ .card .img-wrapper img,
    .card-container /deep/ .card .img-wrapper canvas {
        height: auto;
        width: 100%;
    }
    .card-container /deep/ .card .img-wrapper img,
    .card-container /deep/ .card .img-wrapper canvas {
        aspect-ratio: 280/126;
        object-fit: cover;
    }
    .card-container:hover /deep/ .card .img-wrapper img,
    .card-container:hover /deep/ .card .img-wrapper canvas {
        filter: alpha(opacity=70);
        opacity: 0.7;
    }
    @media (min-width: 576px) {
        .card-container:not(.for-slider) /deep/ .card {
            width: 243px;
        }
    }
    @media (min-width: 768px) {
        .card-container:not(.for-slider) /deep/ .card {
            width: 324px;
        }
    }
    @media (min-width: 992px) {
        .card-container:not(.for-slider) /deep/ .card {
            width: 288px;
        }
    }
    @media (min-width: 1200px) {
        .card-container:not(.for-slider) /deep/ .card {
            width: 320px;
        }
    }
</style>
