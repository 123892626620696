<template>
    <b-button :disabled="disabled" @click="$emit('click')"
              class="btn btn-default apprendo-btn">
        <slot></slot>
    </b-button>
</template>

<script>
    export default {
        name: "apprendo-btn",
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
    .apprendo-btn {
        border-radius: 4px;
        text-align: center;
        border: none;
        font-size: 16px;
        line-height: 22px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .apprendo-btn:hover {
    }

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        .apprendo-btn {
            width: 100%;
            height: 60px;
        }
    }
</style>
