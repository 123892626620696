<template>
    <md-dialog :md-active.sync="openDialog"
               class="modal-dialog sign-up" style="overflow-y: auto;">
        <div class="modal-content" ref="content">
            <div v-if="modalView === 'login'" class="row" style="height: 100%;
            ">
                <div class="signcol col-md-6 col-12 col-sm-12">
                    <div>
                        <ul class="signin nav nav-tabs d-flex mt-2">
                            <li class="nav-item flex-fill">
                                <a class="nav-link active">{{t('Log In')}}</a>
                            </li>
                            <li class="nav-item flex-fill"
                            @click="$emit('change-view', 'signUp')">
                                <a class="nav-link" >{{t('Sign Up')}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-body pt-3">
                        <div class="mb-2">
                            <h4 class="title">{{t('Log In')}}</h4>
                        </div>
                        
                        <button v-if="showFacebookButton" class="btn btn-outline-primary waves-effect waves-themed btn-modal mt-2" @click="fbLogin()" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" height="20" width="20" style="vertical-align: text-bottom;margin-right: 8px;">
                                    	<path d="M11 1c5.523 0 10 4.495 10 10.039 0 5.12-3.817 9.343-8.75 9.961v-7.451h3.438l.312-2.51h-3.75V9.784c0-.693.56-1.255 1.25-1.255H16V6.02h-2.5a3.757 3.757 0 00-3.75 3.765v1.255H7.875v2.51H9.75V21C4.817 20.382 1 16.158 1 11.039 1 5.495 5.477 1 11 1z" vector-effect="non-scaling-stroke" fill="#256AB3" fill-rule="evenodd"/>
                                    </svg>
                                    {{t('Login with')}} Facebook
                                </button>

                        <form @submit.prevent>
                            <div class="buttons">
                                <button class="btn btn-modal" @click="fbLogin()" type="button">
                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                    {{t('Facebook')}}
                                </button>
                            </div>

                            <div v-if="loginError" class="alert alert-danger p-sm-top-bottom p-sm-left-right" role="alert">
                                {{t('Username or password not recognized')}}
                            </div>

                            <div class="mt-4" v-if="showFacebookButton">{{t('Or use your email:')}}</div>

                            <div class="row">
                                <div class="col-md-12">
                                    <md-field :class="{'md-invalid': errors.has('email', 'login')}">
                                        <label>{{t('Email Address or Username')}}</label>
                                        <md-input v-model="email"
                                            name="email"
                                            ref="emailInput"
                                            data-vv-name="email"
                                            data-vv-scope="login"
                                            data-vv-as="Email Address/Username"
                                            @keydown.space="preventLeadingSpace"
                                            v-validate="'required'"></md-input>
                                        <span class="md-error">{{ errors.first('email', 'login') }}</span>
                                    </md-field>
                                </div>

                                <div class="col-md-12">
                                    <md-field :class="{'md-invalid': errors.has('password', 'login')}">
                                        <label>{{t('Password')}}</label>
                                        <md-input v-model="password"
                                                  name="password"
                                                  type="password"
                                                  data-vv-name="password"
                                                  data-vv-scope="login"
                                                  data-vv-as="Password"
                                                  @keyup.enter="doLogin"
                                                  v-validate="'required'"></md-input>
                                        <span class="md-error" v-if="errors.has('password')">{{ errors.first('password', 'login') }}</span>
                                    </md-field>
                                </div>
                            </div>

                            <div class="buttons-footer my-3">
                                <b-btn v-if="!showLoader" @click="doLogin" class="sing-up btn orange waves-effect waves-themed"
                                       :disabled="errors.any('login')">
                                    {{t('Log In')}}
                                </b-btn>
                                <button v-if="showLoader"  type="button" class="btn sing-up disabled text-center">
                                    <img src="/webroot/images/spinner.gif">
                                </button>
                            </div>
                            <a class="btn-link forgot-link"
                               href="/index.php?r=aprendo/forgot" target="_blank" style="font-size: 16px;color: #256ab3;font-weight: normal;">
                                {{t('Forgot your email or Password?')}}
                            </a>
                            <div class="mt-5" style="text-align: center">
                                <div class="clearfix">
                                    <hr/>
                                </div>
                                <b-btn class="btn btn-primary waves-effect waves-themed" @click="$emit('change-view', 'signUp')" style="width: 100%;">
                                    {{t('Create new account')}}
                                </b-btn>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-md-6 p-0-left d-none d-md-block">
                    <img :src="_.get(corporation, 'theme_marketplace_login')">
                </div>
            </div>

            <div v-if="modalView === 'signUp'" class="row">

                <div class="signcol col-md-6 col-12 col-sm-12">
                    <div>
                        <ul class="signin nav nav-tabs d-flex mt-2">
                            <li class="nav-item flex-fill"
                            @click="$emit('change-view', 'login')" >
                                <a class="nav-link">{{t('Log In')}}</a>
                            </li>
                            <li class="nav-item flex-fill"
                            @click="$emit('change-view', 'signUp')">
                                <a class="nav-link active" >{{t('Sign Up')}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-body pt-3">
                        <div>
                            <h4 class="title">{{t('Sign Up')}}</h4>
                        </div>
                        
                        <button v-if="showFacebookButton" class="btn btn-outline-primary waves-effect waves-themed btn-modal mt-2" @click="fbLogin()" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" height="20" width="20" style="vertical-align: text-bottom;margin-right: 8px;">
                                <path d="M11 1c5.523 0 10 4.495 10 10.039 0 5.12-3.817 9.343-8.75 9.961v-7.451h3.438l.312-2.51h-3.75V9.784c0-.693.56-1.255 1.25-1.255H16V6.02h-2.5a3.757 3.757 0 00-3.75 3.765v1.255H7.875v2.51H9.75V21C4.817 20.382 1 16.158 1 11.039 1 5.495 5.477 1 11 1z" vector-effect="non-scaling-stroke" fill="#256AB3" fill-rule="evenodd"/>
                            </svg>
                            {{t('Sign up with')}} Facebook
                        </button>
                        
                    <form @submit.prevent>
                        <div class="buttons">
                            <button class="btn btn-modal" @click="fbLogin()" type="button">
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                                {{t('Facebook')}}
                            </button>
                        </div>

                        <div v-if="signupErrorMessage" class="alert alert-danger p-sm-top-bottom p-sm-left-right" role="alert">
                            {{signupErrorMessage}}
                        </div>

                        <div class="mt-4" v-if="showFacebookButton">{{t('Or use your email:')}}</div>

                        <md-field :class="{'md-invalid': errors.has('full_name', 'signup')}">
                            <label>{{t('Full name')}}</label>
                            <md-input v-model="signupName"
                                      name="full_name"
                                      data-vv-scope="signup"
                                      data-vv-as="Name"
                                      v-validate="{ required: true, regex: /^[a-zA-Z\u00C0-\u017F']+([-'\s][a-zA-Z\u00C0-\u017F]+)*$/ }"></md-input>
                            <span class="md-error">{{ errors.first('full_name', 'signup') }}</span>
                        </md-field>

                        <md-field :class="{'md-invalid': errors.has('signupEmail', 'signup')}">
                            <label>{{t('Email Address')}}</label>
                            <md-input v-model="signupEmail" name="signupEmail"
                                type="email"
                                data-vv-scope="signup"
                                data-vv-as="Email Address"
                                @keydown.space="preventLeadingSpace"
                                v-validate="'required|emailValid'">
                            </md-input>
                            <span class="md-error">{{ errors.first('signupEmail', 'signup') }}</span>
                        </md-field>

                        <md-field :class="{'md-invalid':errors.has('signupPassword', 'signup') | errors.has('confirmation', 'signup')}">
                            <label>{{t('Password')}}</label>
                            <md-input v-model="signupPassword" name="signupPassword"
                                      type="password" autocomplete="off"
                                      data-vv-scope="signup"
                                      data-vv-as="Password"
                                      v-validate="'required'"></md-input>
                            <span class="md-error" v-if="errors.has('signupPassword', 'signup')">
                                {{ errors.first('signupPassword', 'signup') }}
                            </span>
                        </md-field>

                        <md-field class="mb-4"
                                  :class="{'md-invalid':errors.has('signupPassword', 'signup') | errors.has('confirmation', 'signup')}">
                            <label>{{t('Confirm Password')}}</label>
                            <md-input v-model="confirmation" name="confirmation"
                                      type="password" autocomplete="off"
                                      data-vv-scope="signup"
                                      data-vv-as="Password Confirmation"
                                      v-validate="{required: true, is: signupPassword}"></md-input>
                            <span class="md-error" v-if="errors.has('confirmation', 'signup')">
                                {{ errors.first('confirmation', 'signup') }}
                            </span>
                        </md-field>


                        <!-- <div class="buttons-footer"> -->
                        <div class="">
                            <b-btn v-if="!showLoader"
                                   @click="doSignup()" class="sing-up btn orange waves-effect waves-themed"
                                   :disabled="errors.any('signup')">
                                {{t('Create new account')}}
                            </b-btn>

                            <button v-if="showLoader"
                                    class="btn sing-up disabled" type="button">
                                <img src="/webroot/images/spinner.gif">
                            </button>

                            <span class="terms mt-2">
                                {{t('By signing up, you agree to our')}} <a href="javascript:">{{t('Terms')}}</a>
                            </span>
                        </div>
                        <div class="">
                            <div class="clearfix">
                                <hr/>
                            </div>
                            <b-btn class="btn btn-primary waves-effect waves-themed" @click="$emit('change-view', 'login')" style="width: 100%;">
                                {{t('Log In, already have an account')}}
                            </b-btn>
                        </div>
                    </form>
                    </div>
                </div>

                <div class="col-md-6 p-0-left d-none d-md-block">
                    <img class="image-full" :src="corporation.theme_marketplace_registration">
                </div>
            </div>
        </div>
    </md-dialog>
</template>

<script>

import API from '@/api/index';
import { Validator } from "vee-validate";

export default {
    name: "LoginModal",
    props: {
        showDialog: {
            type: Boolean
        },
        corporation: {
            type: Object
        },
        modalView: {
            type: String,
            default() {
                return 'login';
            }
        }
    },
    data() {
        return {
            showLoader: false,
            loginError: false,
            signupErrorMessage: null,
            remember: false,
            email: null,
            password: null,
            openDialog: false,
            signupEmail: null,
            signupName: null,
            signupPassword: null,
            confirmation: '',
            debugValue: '',
        }
    },
    watch: {
        showDialog() {
            this.openDialog = true;
        },
        openDialog(val) {
            if (!val) {
                //reset forms
                this.email = null;
                this.password = null;
                this.signupEmail = null;
                this.signupName = null;
                this.signupPassword = null;
                this.confirmation = null;
            }
        }
    },
    methods: {
        preventLeadingSpace(e) {
            e.preventDefault();
        },
    	fbLogin(){
    		
    		FB.login(function(response){
    		
                this.openDialog = false;
                this.showLoader = true;
    			console.log("lefacebook response")
    			console.log(response);
    		
    			if(response.authResponse.accessToken){
    				
    				$.post("index.php?r=aprendo/processFacebook",{token:response.authResponse.accessToken},function(data,response){

						
						//var leUrl = URI()
                        
                        //Solo cuando se loguean desde el landing page
                       
                       
                        /**if(parseInt(leUrl.segment(-1))>0 && (leUrl.segment(-2)=="courses" || leUrl.segment(-2)=="course" || leUrl.segment(-2)=="index")){
                        
                        	window.location = location.href.slice(0, -1) + "/enroll";
                        	
                        	location.reload();
                        
                        }else{
                        	location.reload();
                        }**/
                        location.reload();

    				})
    				
    			}

			  // handle the response 
			}, {scope: 'email',
                return_scopes: true});
    	
    	
    	},
        async doSignup() {
            const isValid = await this.$validator.validateAll('signup');

            if (isValid) {
                try {
                    this.showLoader = true;
                    this.signupErrorMessage = null;
                    await API.main.registerUser({
                        id_corporation: this.corporation.id,
                        name: this.signupName,
                        email: this.signupEmail.trim(),
                        password: this.signupPassword
                    });
                    this.email = this.signupEmail.trim();
                    this.password = this.signupPassword;
                    await this.doLogin();
                } catch (e) {
                    this.signupErrorMessage = _.get(e, 'response.data.message', 'Error');
                } finally {
                    this.showLoader = false;
                }
            }
        },
        async doLogin() {
            const isValid = await this.$validator.validateAll('login');

            if (isValid) {
                try {
                    this.showLoader = true;
                    const apiResponse = await API.main.login({
                        id_corporation: this.corporation.id,
                        username: this.email.trim(),
                        password: this.password
                    });

                    if (apiResponse.data.token) {
                        await API.phpAppEndpoints.processLogin({
                            username: this.email.trim(),
                            api_token: apiResponse.data.token
                        });
                        this.loginError = false;
                        this.$validator.pause();
                        this.openDialog = false;

                        //refresh to get user data after login
                        
                        //var leUrl = URI();
                        
                        //Solo cuando se loguean desde el landing page
                       
                       
                        /**if(parseInt(leUrl.segment(-1))>0 && (leUrl.segment(-2)=="courses" || leUrl.segment(-2)=="course" || leUrl.segment(-2)=="index")){
                            window.location = `${location.href.slice(0, location.href.indexOf('#') + 1)}${location.href.indexOf('/enroll') == -1 ? '/enroll' : '/'}`;
                        }else{
                        	location.reload();
                        }**/
                        location.reload();
                        
                        //location.reload();
                        this.$emit('refresh-session');
                    } else {
                        this.loginError = true;
                        this.showLoader = false;
                    }

                } catch (e) {
                    this.loginError = true;
                } finally {
                    this.showLoader = false;
                }
            }
        }
    },
    computed:{
        _() {
            return _;
        },
        showFacebookButton(){
            if (window.location.href.includes('chilaquiles') || window.location.href.includes('salud') || window.location.href.includes('nontraditional')) {
                return true
            }{
                return false
            }
    	},
    },
    mounted() {
        const dict = {
            custom: {
                full_name: {
                    regex: 'Please type your full name'
                },
            }
        };
        Validator.extend("emailValid", {
            validate: (value) => {
                console.log('emailValid');
                let emailRex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return emailRex.test(value);
            },
        });
        this.$validator.localize('en', dict);
    }
}
</script>


<style scoped>
    .md-dialog {
        width: 760px;
    }

    .forgot-link {
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #256ab3;
        text-decoration: none;
        margin-top: 30px;
        padding-left: 0;
        background-color: transparent;
    }
    .btn-outline-primary {
    border-color: #256ab3;
    color: #383838;
    background-color: #fff;
    width: 100%;
}
.btn-outline-primary:hover {
    background-color: #d3e1ef !important;
}

.modal-dialog .terms {
    font-family: "Source Sans Pro";
    font-size: 12px;
    line-height: 1.33;
    color: #535353;
    display: inline-block;
    max-width: none;
    position: initial;
}

img:not(.md-image){
    height: 100% !important;
    /* height: -webkit-fill-available; */
}

.image-full{
    height: 100% !important;
    min-height: -webkit-fill-available;
}

.modal-dialog.sign-up .modal-content {
    min-height: 593px;
}

/* .sing-up:hover {
    color: white !important;
    text-decoration: none;
    background-color: #B85419;
} */
</style>
