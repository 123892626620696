<template>
    <md-dialog :md-active.sync="openDialog"
               class="modal-dialog sign-up email-preview">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-right" style="cursor: pointer" @click="openDialog = false">
                        <close-icon class="close-btn" @click="openDialog = false"
                                    width="15" height="15"></close-icon>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <svg width="133" height="105" xmlns="http://www.w3.org/2000/svg">
                        	<g fill="none" fill-rule="evenodd">
                        		<path d="M4.177 40.743C1.87 40.743 0 38.86 0 36.538s1.87-4.205 4.177-4.205c2.307 0 4.177 1.883 4.177 4.205s-1.87 4.205-4.177 4.205zm0-1.53a2.667 2.667 0 0 0 2.658-2.675 2.667 2.667 0 0 0-2.658-2.676 2.667 2.667 0 0 0-2.658 2.676 2.667 2.667 0 0 0 2.658 2.676zM29.578 17.41c-2.307 0-4.177-1.883-4.177-4.205S27.271 9 29.578 9c2.307 0 4.177 1.883 4.177 4.205s-1.87 4.205-4.177 4.205zm0-1.53a2.667 2.667 0 0 0 2.658-2.675 2.667 2.667 0 0 0-2.658-2.676 2.667 2.667 0 0 0-2.658 2.676 2.667 2.667 0 0 0 2.658 2.676zM118.648 83.243c-2.307 0-4.177-1.883-4.177-4.205s1.87-4.205 4.177-4.205c2.306 0 4.176 1.883 4.176 4.205s-1.87 4.205-4.176 4.205zm0-1.53a2.667 2.667 0 0 0 2.658-2.675 2.667 2.667 0 0 0-2.658-2.676 2.667 2.667 0 0 0-2.659 2.676 2.667 2.667 0 0 0 2.659 2.676z" fill="#F3A93C" fill-rule="nonzero"/>
                        		<g stroke="#00C59D" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="2">
                        		<path d="M10.472 73.832l.1 3.351M9.11 78.913l-3.586.024M10.597 80.818l-.025 3.353M16.28 78.867l-3.586.023"/></g>
                        		<g stroke="#00C59D" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="2">
                        		<path d="M122.06 21.165l.1 3.351M120.697 26.247l-3.585.023M122.185 28.152l-.025 3.352M127.868 26.2l-3.585.023"/></g><g>
                        		<path d="M42.96 10v.098h-.097v83.713H4v1.04h38.863v.097h6.09V16.033h35.802l-.17 63.411h3.658v15.408h.672V95H133v-1.04H94.335V10H42.96zm44.777 20.565h.506V16.033h-.506v14.532zm0 42.067h.506V37.669h-.506v34.963z" fill="#F3A93B"/><path fill="#F68B1F" d="M58 0v105l29-9.925V15.672z"/>
                        		<path d="M67 61a2 2 0 1 1-4 0 2 2 0 0 1 4 0" fill="#BA5F45"/>
                        	</g></g>
                        </svg>
                        <h3 class="mt-5"><span v-if="custom_waitlist.custom_waitlist && custom_waitlist.custom_waitlist_6">{{custom_waitlist.custom_waitlist_6}}</span><span v-else>{{t('Do you want to be removed from the waitlist?')}}</span>
                            </h3>

                        <div v-if="!message" class="mt-4 text-center">
                            <p class="mx-0 my-0">
                                <span v-if="custom_waitlist.custom_waitlist && custom_waitlist.custom_waitlist_7">{{custom_waitlist.custom_waitlist_7}}</span>
                                <span v-else>{{t('You´re currently waiting for a spot (' + (seat_name ? seat_name : '') + '), on this event.')}}</span>
                            </p>
                            <p class="mx-0 my-0">
                                <span v-if="custom_waitlist.custom_waitlist && custom_waitlist.custom_waitlist_7"></span><span v-else>{{t('If you get out you may lose it.')}}</span>
                            </p>
                        </div>
                        <div v-else v-html="message" class="mt-4 text-center fr-view"></div>
                        
                        <div id="btnrow" class="row mt-5">
                        	<div class="col-6 py-3 text-left">
                        		<button  @click.stop.prevent="removeFromWaitlist();openDialog = false" class="btn btn-outline-primary cancel">
                        		{{custom_waitlist.custom_waitlist && custom_waitlist.custom_waitlist_8 ? custom_waitlist.custom_waitlist_8 : t('Yes, remove me from the list')}}</button>
                   			 </div>
	                        <div class="col-6 py-3">
                       			 <button @click.stop.prevent="openDialog = false"
                                class="btn btn-primary pull-right">{{t('No, I want to wait')}}</button>
                    		</div>
	
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </md-dialog>
</template>

<script>
import API from '@/api/index';
import CloseIcon from '@/components/Icons/CloseIcon';

export default {
    name: "WaitListRemoveModal",
    props: {
        value: {
            type: Boolean,
        },
        idCourse: {
            type: Number
        },
        message: {
            type: String,
            default() {
                return '';
            }
        },
        seat_name: {
            type: String,
            default() {
                return '';
            }
        },
        custom_waitlist: {
            type: Object,
        },
    },
    data() {
        return {
            openDialog: false
        }
    },
    components: {
        CloseIcon
    },
    methods : {
    	async removeFromWaitlist(){
    	
    	 	let loader = this.$loading.show();
            const apiResponse = await API.main.addToWaitList(this.idCourse);
			this.$emit('refresh');
			this.$emit('changeWaitlist', false);
            this.waitlistMessage = apiResponse.data.message;
            this.openDialog =  false;

            loader.hide();
    	
    		console.log(this.idCourse);
    	
    	}
    },
    watch: {
        value(val) {
            this.openDialog = val;
        },
        openDialog(val) {
            if (!val) {
                //this.$emit('refresh');
            }
            this.$emit('input', val);
        }
    },
}
</script>

<style scoped>
    .btn-default.blue,
    .btn-default.blue:hover {
        width: 250px;
        height: 35px;
        color: white !important;
        text-decoration: none;
        padding-top: 8px;
        font-size: 18px;
        line-height: 22px;
        font-family: "Source Sans Pro";
    }
    .btn-outline-primary {
    color: #383838;
    background-color: #ffffff;
    background-image: none;
    border-color: #256AB3;
}
.btn-outline-primary:hover {
    background-color: #D3E1EF;   
}

    h3 {
        color: #383838;
        font-family: "Source Sans Pro";
        font-size: 40px;
        line-height: 46px;
        text-align: center;
    }

    p {
        color: #383838;
        font-family: "Source Sans Pro";
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }
</style>