<template>
  <div class="menus-div">
    <p class="menu-label" v-if="label">{{ label }}</p>
    <ul class="menu-list list-unstyled" :class="type" :style="type == 'float' ? 'max-height: calc(70vh); top: 0;' : ''">
      <slot></slot>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: {
      type: String,
      default: 'collapse',
    },
  },

  data() {
    return {
      typeClass: this.type,
      isOpen: false,
      timeOut: null,
    };
  },

  methods: {
    hasOpened() {
      this.$children.every((item) => {
        if (item.isOpen) this.isOpen = true;
        return true;
      });
      return false;
    },
    onMouseLeave() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }

      this.timeOut = setTimeout(() => {
        this.$emit('close');
      }, 150);
    },
    onMouseEnter() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }
    }
  },

  mounted() {
    this.hasOpened();
    this.$el.addEventListener('mouseleave', this.onMouseLeave);
    this.$el.addEventListener('mouseenter', this.onMouseEnter);
  },
  beforeDestroy() {
    this.$el.removeEventListener('mouseleave', this.onMouseLeave);
    this.$el.removeEventListener('mouseenter', this.onMouseEnter);
  }
};
</script>

<style scoped>
  .menus-div {
    width: 250px;
    padding-right: 5px;
  }
</style>