<template>
<div>
    <svg width="20" height="26" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: -8px;">
        <path d="M19.174 0H0l14.222 12.857L0 25.714h19.174z" fill="#73D2F6" fill-rule="evenodd"/>
    </svg>
    <span class="flagwaiting">{{t('WAITING')}}</span>
    <svg width="11" height="32" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: -14px;margin-left: -4px;">
        <defs><path d="M11 28.85c-.017-2.018-.948-3.15-2.086-3.15H3V32h6.215c.981 0 1.776-1.408 1.785-3.15z" id="awaitingsh"/></defs>
        <g fill="none" fill-rule="evenodd"><path d="M11 3.635C11 1.628 8.299 0 4.969 0H0v25.714h3.889C7.8 25.714 11 26.643 11 29V3.635z" fill="#73D2F6"/><g><use fill="#0283B3" xlink:href="#awaitingsh"/></g></g>
    </svg>
</div>
</template>

<script>
    export default {
        name: "WaitingFlag"
    }
</script>

<style scoped>
 .flagwaiting{
        color:white;
        background-color: #73D2F6;
        padding: 6px 8px 5px;
    margin-left: -5px;
        position: relative;
    top: 6px;
    }

</style>
