<template>
  <div :class="{'has-error': errors.has(inputId)}">
    <label :for="inputId" v-html="deleteTags(label)"></label>
    <label v-if="required" class="red"> *</label>

    <b-input-group class="mb-3">
      <b-input-group-prepend>
        <b-form-datepicker
          :id="inputId"
          v-model="dateCalendar"
          menu-class="m-w-100"
          calendar-width="100%"
          :show-decade-nav="true"
          button-only
          dropright
          today-button
          close-button
          :locale="locale"
          :aria-controls="uniqueId"
          selected-variant="info"
          ref="datepicker"
          @input="updateDateSelected"
          button-variant="outline-primary"
          :disabled="notEditable == 1 && level_access == 3"
        ></b-form-datepicker>
      </b-input-group-prepend>
      <b-form-input
        v-model="formattedDate"
        type="text"
        :placeholder="formatValue"
        autocomplete="off"
        disabled="disabled"
        @click="openDatepicker"
      ></b-form-input>
    </b-input-group>
    <input type="hidden"  v-model="inputVal" :name="inputId" v-validate="validations" >
    <span class="md-error" v-if="required && errors.collect(inputId).length && inputVal=='' " >
      {{t('This field is required.')}}
    </span>
    <span class="md-error" v-else>
      {{ errors.first(inputId) }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "checkout-form-input-date",
    props: ['value', 'label', 'required', 'inputValidations', 'uniqueId','notEditable','format'],
    data() {
      return {
        locale: 'en-US',

        inputVal: this.value,
        dateCalendar: this.value,
        formatValue: '',
        formattedDate: '',
        inputId: null,
        labels:{}
      }
    },
    inject: ['$validator'],
    computed: {
      _() {
        return _;
      },
      validations() {
        let validations = {};

        if (this.required) {
          validations.required = true;
        }

        if (this.inputValidations) {
          const dbValidations = JSON.parse(this.inputValidations);
          validations = Object.assign(validations, dbValidations);
        }


        return validations;
      }
    },
    mounted: function() {
        var _this = this;
        if (_this.value && _this.value !== ''){
          const dateObj = new Date(parseInt(_this.value));
          const year = dateObj.getUTCFullYear();
          const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
          const day = dateObj.getUTCDate().toString().padStart(2, '0');
          var formattedDate = '';
          switch (_this.formatValue) {
              case 'dd-mm-yyyy':
                  formattedDate = `${day}-${month}-${year}`;
                  break;
              default: //'yyyy-mm-dd',
                  formattedDate = `${year}-${month}-${day}`;
                  break;
          }
          _this.formattedDate = formattedDate;
        }
    },
    watch: {
      inputVal(val) {
        this.$emit('input', val);
      },
    },
    created() {
      this.format = typeof(this.format) !== 'undefined' &&this.format!==''&& JSON.parse(this.format)?JSON.parse(this.format):'yyyy-mm-dd';
      this.format = typeof(this.format.format) !== 'undefined'?this.format.format:'yyyy-mm-dd';
      this.formatValue = this.format;
      this.inputId = this.uniqueId || _.uniqueId();
      var baselabels = {
        labelPrevDecade: this.t('Previous Decade'),
        labelPrevYear: this.t('Previous Year'),
        labelPrevMonth: this.t('Previous Month'),
        labelCurrentMonth: this.t('Current Month'),
        labelNextMonth: this.t('Next Month'),
        labelNextYear: this.t('Next Year'),
        labelNextDecade: this.t('Next Decade'),
        labelToday: this.t('Today'),
        labelSelected: this.t('Selected Date'),
        labelNoDateSelected: this.t('No Date Selected'),
        labelCalendar: this.t('Calendar'),
        labelNav: this.t('Calendar Navigation'),
        labelHelp: this.t('Use arrow keys to navigate the calendar'),
      };
      this.labels.es = baselabels;
      this.labels.fr = baselabels;
    },
    methods: {
      deleteTags(label) {
        //let result = label.replace(/<[^>]*>?/gm, '');
        let result = label.replace(/\s*style=(["'])(.*?)\1/gmi, '')
        .replace(/<p[^>]*>/gmi, '');

        return result;
      },
      openDatepicker() {
        this.$refs.datepicker.focus();
      },
      updateDateSelected(value) {
        const isoString = new Date(value).toISOString();

        const year = isoString.substring(0, 4);
        const month = isoString.substring(5, 7);
        const day = isoString.substring(8, 10);

        var formattedDate = '';
        switch (this.formatValue) {
            case 'dd-mm-yyyy':
                formattedDate = `${day}-${month}-${year}`;
                break;
            default: //'yyyy-mm-dd',
                formattedDate = `${year}-${month}-${day}`;
                break;
        }
        this.formattedDate = formattedDate;
        this.inputVal = new Date(value).getTime();
      }
    }
  }
</script>

<style scoped>
  .red {
    color: #D95A4B !important;
  }
  .picker__select--year{
    padding-bottom: 0px!important;
  }
    .picker__input.form-control[readonly] {
        background-color: rgb(255, 255, 255) !important;
    }
    .picker__input.form-control:disabled {
        background-color: rgb(227,229,232) !important;
        border-color: rgb(159,165,172);
    }
    table.picker__table thead tr:hover,
    table.picker__table tbody tr:hover {
        background-color: inherit !important;
        -moz-box-shadow: inherit !important;
        -webkit-box-shadow: inherit !important;
        box-shadow: inherit !important;
    }
    .picker.hide-clear .picker__button--clear {
        display: none !important;
    }
</style>
