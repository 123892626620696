<template>
    <b-dropdown class="categories-menu mt-2"
                variant="link"
                no-caret>        
        <template slot="button-content" v-if="!catalogue_name">
            <span class="category-option"
                  data-agastya-ignore
                  v-if="categoryType === CATEGORIES_TYPES.CATEGORY">
                {{t('Categories')}}
            </span>
            <span class="category-option"
                  data-agastya-ignore
                  v-else-if="categoryType === CATEGORIES_TYPES.HOST">
                {{t('Hosts')}}
            </span>
            <span class="category-option"
                  data-agastya-ignore
                  v-else-if="categoryType === CATEGORIES_TYPES.COMPETENCES">
                {{t('Competencies')}}
            </span>
             <span class="category-option"
                  data-agastya-ignore
                  v-else-if="categoryType === CATEGORIES_TYPES.FORMATS">
                {{t('Formats')}}
            </span>
            <span ref="closeIcon" class="icon is-small ml-1" data-agastya-ignore>
                <i class="fa fa-angle-down"></i>
            </span>
        </template>
          <template slot="button-content" v-else>
            <div ref="divcategory" v-if="labelCategory">
                <span class="category-option"
                    data-agastya-ignore
                    v-if="categoryType === CATEGORIES_TYPES.CATEGORY && labelCategory">

                    {{labelCategory ? labelCategory : t('Category')}}
                </span>
                <span  v-if="categoryType === CATEGORIES_TYPES.CATEGORY && labelCategory" ref="closeIcon" class="icon is-small ml-1" data-agastya-ignore>
                    <i class="fa fa-angle-down"></i>
                </span>
             </div>
              <div id="divhost"  v-if="labelHost != ''">
                <span class="category-option"
                    data-agastya-ignore
                    v-if="categoryType === CATEGORIES_TYPES.HOST && labelHost">
                    {{labelHost ? labelHost : t('Host')}}

                </span>
                <span v-if="categoryType === CATEGORIES_TYPES.HOST && labelHost" ref="closeIcon" class="icon is-small ml-1" data-agastya-ignore>
                    <i class="fa fa-angle-down"></i>
                </span>
              </div>
             <div ref="divcompetence"  v-if="labelCompetence">
                <span class="category-option"
                    data-agastya-ignore
                    v-if="categoryType === CATEGORIES_TYPES.COMPETENCES && labelCompetence">

                    {{labelCompetence ? labelCompetence : t('Competences')}}
                </span>
                <span  v-if="categoryType === CATEGORIES_TYPES.COMPETENCES && labelCompetence" ref="closeIcon" class="icon is-small ml-1" data-agastya-ignore>
                    <i class="fa fa-angle-down"></i>
                </span>  
            </div>          
        </template>

        <menus @close="closeDropDown()">
            <menu-item v-for="category in topCategories"
                       :data-path="getCategoryPath(category)"
                       :click="goToPath"
                       @click="openCategory(getCategoryPath(category))"
                       :key="category.id_category">
                <template v-if="children(category.id_category).length">
                    <span>{{category.category_name}}</span>
                    <menus slot="sub" type="float" style="overflow-y: auto;">
                        <menu-item @click="openCategory(getCategoryPath(category))"
                                    class="category-title">
                          <template v-if="category.imageURL" >
                              <b-img-lazy 
                                :src="category.imageURL"
                                :placeholder="category.imageURL"
                                :blank-color="canvasGradient()"
                              ></b-img-lazy>
                          </template>
                                <!-- 
                                <img v-if="category.imageURL" :src="category.imageURL" class="img-fluid" loading="lazy" @error="category.imageURL=null;" />
                                -->
                                <canvas v-else class="img-fluid" :style="{ backgroundImage: canvasGradient() }" ></canvas>
                                <h4 class="position-absolute" data-agastya-ignore>{{category.category_name}}</h4>
                        </menu-item>
                        <menu-item v-for="subCategory in children(category.id_category)"
                                    :data-path="getCategoryPath(subCategory)"
                                    :click="goToPath"
                                    @click="openCategory(getCategoryPath(subCategory))"
                                    :key="subCategory.id_category">
                                <template v-if="children(subCategory.id_category).length">
                                    <span>{{subCategory.category_name}}</span>
                                    <menus slot="sub" type="float">
                                        <menu-item @click="openCategory(getCategoryPath(subCategory))"
                                                    class="category-title">
                          <template v-if="subCategory.imageURL" >
                              <b-img-lazy 
                                :src="subCategory.imageURL" 
                                :blank-color="canvasGradient()"
                                class="img-fluid" 
                              ></b-img-lazy>
                          </template>
                                <!-- 

                                            <img v-if="subCategory.imageURL" :src="subCategory.imageURL" class="img-fluid" loading="lazy" @error="subCategory.imageURL=null;" />
                                -->
                                            <canvas v-else class="img-fluid" :style="{ backgroundImage: canvasGradient() }" ></canvas>
                                            <h4 class="position-absolute" data-agastya-ignore>{{subCategory.category_name}}</h4>
                                        </menu-item>
                                        <menu-item v-for="level3Category in children(subCategory.id_category)"
                                                :data-path="getCategoryPath(level3Category)"
                                                :click="goToPath"
                                                @click="openCategory(getCategoryPath(level3Category))"
                                                :key="level3Category.id_category">
                                            {{level3Category.category_name}}
                                        </menu-item>
                                    </menus>
                                </template>
                                <template v-else>
                                    {{subCategory.category_name}}
                                </template>
                        </menu-item>
                    </menus>
                </template>
               <template v-else>
                   {{category.category_name}}
               </template>
            </menu-item>
        </menus>
    </b-dropdown>
</template>

<script>
import API from '@/api/index';
import { CATEGORIES_TYPES, PAGES } from '@/utils';
import Menus from '@/components/menu/Menus';
import MenuItem from '@/components/menu/MenuItem';
import {  mapState, mapMutations, } from "vuex";
import { BImgLazy } from 'bootstrap-vue';

export default {
    name: "CategoriesMenu",
    data() {
        return {
            //categories: []
            labelHost: '',
            labelCategory: '',
            labelCompetence: ''
        }
    },
    components: {
        Menus,
        MenuItem,
        BImgLazy
    },
    props: {
        corporationId: {
            type: Number
        },
        categoryType: {
            type: Number
        },
        catalogue_name: {
            type: String
        },
        label_category_catalogue: {
            type: Object
        }
    },
    computed: {
        ...mapState(['categories']),
        topCategories() {            
            return _.filter(this.categoriesMenu, {id_parent_category: null});
        },
        CATEGORIES_TYPES() {
            return CATEGORIES_TYPES;
        },
        categoriesMenu() {    
            if(this.catalogue_name){
                let labelCategoryCatalogue = this.label_category_catalogue; 
                let labelCategoryOption = labelCategoryCatalogue.find(x => x.id_category_type == this.categoryType);
               let labelCategory = labelCategoryOption && labelCategoryOption.show_menu ? (labelCategoryOption.label_category_type ? labelCategoryOption.label_category_type : 'OK') : '';
                if(labelCategory == ''){
                    return [];
                }             
               
            }      
            return !!this.categories ? this.categories[this.categoryType]||[] : [];
        },
    },
    methods: {
        ...mapMutations(['setCategories']),
        ...mapState(['categories']),
        closeDropDown() {
            this.$refs.closeIcon.click();
        },
        async getLabelCategoryCatalogue(){            
                let labelCategoryCatalogue = this.label_category_catalogue; 
                if(labelCategoryCatalogue){
                   let labelHostOption = labelCategoryCatalogue.find(x => x.id_category_type == CATEGORIES_TYPES.HOST);
                   this.labelHost = labelHostOption && labelHostOption.show_menu ? (labelHostOption.label_category_type ? labelHostOption.label_category_type : 'Hosts') : '';
                   
                   let labelCategoryOption = labelCategoryCatalogue.find(x => x.id_category_type == CATEGORIES_TYPES.CATEGORY);
                   this.labelCategory = labelCategoryOption && labelCategoryOption.show_menu  ? (labelCategoryOption.label_category_type ? labelCategoryOption.label_category_type : 'Categories') : '';
                   
                   let labelCompetenceOption = labelCategoryCatalogue.find(x => x.id_category_type == CATEGORIES_TYPES.COMPETENCES);
                   this.labelCompetence = labelCompetenceOption && labelCompetenceOption.show_menu  ? (labelCompetenceOption.label_category_type ? labelCompetenceOption.label_category_type : 'Competencies') : '';
                  
                }               
        },
        loadCategories() {
            let promises = [];

            if (this.categoryType === CATEGORIES_TYPES.HOST) {
                promises.push(API.domain.categoriesByCorpAndType(this.corporationId, CATEGORIES_TYPES.HOST, CATALOGUE_PATH || ""));
            }

            if (this.categoryType === CATEGORIES_TYPES.CATEGORY) {
                promises.push(API.domain.categoriesByCorpAndType(this.corporationId, CATEGORIES_TYPES.CATEGORY, CATALOGUE_PATH || ""));
            }

            if (this.categoryType === CATEGORIES_TYPES.COMPETENCES) {
                promises.push(API.domain.categoriesByCorpAndType(this.corporationId, CATEGORIES_TYPES.COMPETENCES, CATALOGUE_PATH || ""));
            }

            if (this.categoryType === CATEGORIES_TYPES.FORMATS) {
                promises.push(API.domain.categoriesByCorpAndType(this.corporationId, CATEGORIES_TYPES.FORMATS, CATALOGUE_PATH || ""));
            }

            Promise.all(promises).then((values) => {
                values.forEach((catType) => {
                    this.setCategories([this.categoryType, _.filter(catType.data.data, {active: true})]);
                });
            });
        },
        children(id_cat) {
            return _.filter(this.categoriesMenu, {id_parent_category: id_cat});
        },
        parentCategory(id) {
            return _.find(this.categoriesMenu, {id_category: id});
        },
        getCategoryPath(category) {
            if (category.id_parent_category) {
                return this.getCategoryPath(this.parentCategory(category.id_parent_category)) + '/' + _.snakeCase(category.category_name);
            } else {
                return '/' + _.snakeCase(category.category_name);
            }
        },
        goToPath(event) {
            window.location.href = (CATALOGUE_PATH ? CATALOGUE_PATH.substring(0, CATALOGUE_PATH.length - 1)+"#" :  PAGES.LIST + '#' ) + _.get(event, 'target.parentNode.parentNode.dataset.path', '');
        },
        openCategory(path) {
            window.location.href = (CATALOGUE_PATH ? CATALOGUE_PATH.substring(0, CATALOGUE_PATH.length - 1)+"#" :  PAGES.LIST + '#' ) + path;
        },
        getRandomColor() {
            // if randomColor cndjs is not available
            let letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16777216)];
            }
            return color;
        },
        canvasGradient() {
            let a = typeof randomColor == 'function' ? randomColor() : this.getRandomColor();
            let b = typeof randomColor == 'function' ? randomColor() : this.getRandomColor();
            return `linear-gradient(to right, ${a}, ${b})`;
        },
    },
    async created() {       
        if(this.catalogue_name){          
            await this.getLabelCategoryCatalogue();
        }
    },
}
</script>

<style>
    .category-title h4 {
        top: 10px;
        height: 22px;
        color: #FFFFFF;
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-shadow: 0 2px 4px rgba(0,0,0,0.5);
        padding-left: 16px;
    }

    .category-title a {
        padding-left: 0 !important;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .menu-list a {
        opacity: initial;
    }

    .menu-list span a {
        color: #383838 !important;
        text-decoration: none !important;
        font-family: "Source Sans Pro" !important;
        font-size: 18px !important;
        font-weight: normal !important;
    }

    span.category-option,
    a.button.is-primary span,
    a.button.is-primary {
        background-color: transparent !important;
        border: none !important;
        color: #256AB3 !important;
        font-size: 18px !important;;
        font-family: "Source Sans Pro" !important;
    }

    .menu-list span a:hover {
        background-color: #E3E5E8 !important;
    }

    .has-children span {
        color: #383838;
        font-size: 18px;
    }

    .menu-list.float,
    .popover-content {
        width: 270px;
    }

    .categories-menu .btn.btn-link span {
        color: #383838 !important;
        font-size: 18px !important;;
    }


    .categories-menu .btn.btn-link,
    .categories-menu .btn.btn-link:hover {
        text-decoration: none;
    }
</style>

<style scoped>
.category-title a canvas.img-fluid {
    aspect-ratio: 1100/330;
    object-fit: cover;
}
</style>