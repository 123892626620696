<template>
    <span @click="playSound()"
          v-tooltip="{placement: 'bottom', classes: 'fontBtn', content: '<p>Click to listen and stop the audio</p>'}"
          :style="{top: top, left: left}"
          :class="{'d-none': !showIcon}">
            <svg width="33px" height="33px" viewBox="0 0 33 33" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                <title>sound</title>
                <desc>Created with Sketch.</desc>
                <g id="Course-Templates" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Marketplace-GRID-simple" transform="translate(-830.000000, -86.000000)" fill="#214a58" fill-rule="nonzero">
                        <g id="uikit/input/drop/select/menu" transform="translate(777.000000, 86.000000)">
                            <g id="sound" transform="translate(53.000000, 0.000000)">
                                <path d="M16.5,0 C25.575,0 33,7.425 33,16.5 C33,25.575 25.575,33 16.5,33 C7.425,33 0,25.575 0,16.5 C0,7.425 7.425,0 16.5,0 Z M19.721101,7.05389615 C19.5088019,6.95501955 19.2965029,7.00445785 19.1372786,7.10333445 L14.3074757,10.9595221 L9.53074757,10.9595221 C9.21229903,10.9595221 9,11.1572753 9,11.4539051 L9,20.5505528 C9,20.8471826 9.21229903,21.0449358 9.53074757,21.0449358 L14.3074757,21.0449358 L19.1372786,24.9011234 C19.2434281,25 19.3495777,25 19.4557272,25 C19.5088019,25 19.6149514,25 19.6680262,24.9505617 C19.8803252,24.8516851 19.9864747,24.7033702 19.9864747,24.505617 L19.9864747,7.49884088 C20.0395495,7.30108766 19.9334,7.15277276 19.721101,7.05389615 Z M21.4764397,20.5876964 C21.1778329,20.7692443 21.1181115,21.1323402 21.2375543,21.4349201 C21.356997,21.616468 21.5361611,21.7375 21.7750465,21.7375 C21.8944892,21.7375 21.9542106,21.7375 22.0736533,21.676984 C23.8652941,20.6482124 25,18.6511851 25,16.5936419 C25,14.4755826 23.8652941,12.5390713 22.0736533,11.5102997 C21.7750465,11.3287518 21.4167183,11.4497837 21.2375543,11.7523636 C21.0583902,12.0549435 21.1778329,12.4180394 21.4764397,12.5995873 C22.9097524,13.446811 23.8055728,14.9597104 23.8055728,16.6541578 C23.8055728,18.2275733 22.9097524,19.7404727 21.4764397,20.5876964 Z" id="Combined-Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
    </span>
</template>

<script>
    export default {
        name: "PlayAloudText",
        data() {
            return {
                audioEl: null,
                showIcon: false,
                top: 0,
                left: 0,
            }
        },
        methods: {
            playSound() {
                if (this.audioEl && this.audioEl.paused) {
                    this.audioEl.play();
                } else if(this.audioEl) {
                    this.audioEl.pause();
                }
            },
            handleMouseUp(event) {
                if (_.get(event, 'target.tagName', null) === 'path' ||
                    _.get(event, 'target.tagName', null) === 'svg' ||
                    _.get(event, 'target.tagName', null) === 'g') {
                    //event.stopPropagation();
                    //event.preventDefault();
                    //this.playSound();
                    return;
                }

                let selection = window.getSelection();
                if (selection && selection.type === 'Range') {
                    //const url = window.agastya.api("read-aloud-link", { text: selection.toString() });
                    //console.log(url);
                    const url = 'https://developer.oswaldlabs.com/read-aloud?text='+encodeURI(selection.toString());
                    this.audioEl = new Audio(url);

                    this.top = event.pageY + 10 - window.scrollY + 'px';
                    this.left = event.pageX + 'px';

                    this.showIcon = true;
                } else {
                    this.showIcon = false;
                }
            },
        },
        mounted() {
            window.addEventListener('mouseup', this.handleMouseUp);
        },
        destroyed() {
            window.removeEventListener('mouseup', this.handleMouseUp);
        },
    }
</script>

<style scoped>
    span {
        position: fixed;
        cursor: pointer;
        background-color: white;
        border-radius: 20px;
        z-index: 9999999999999999999;
    }
</style>
