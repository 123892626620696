<template>
  <li :class="{ 'is-active': isActive }">
    <span v-if="!hasChildren" @click="$emit('click')" data-agastya-ignore>
      <router-link :to="to" v-if="!click" exact>
        <i class="fa" :class="[`fa-${icon}`]" v-if="icon"></i>
        <slot></slot>
      </router-link>
      <a href="javascript:void(0)" v-if="click" @click="click" data-agastya-ignore>
        <i class="fa" :class="[`fa-${icon}`]" v-if="icon"></i>
        <slot></slot>
      </a>
    </span>

    <span v-if="hasChildren">
      <a class="has-children"
         data-agastya-ignore
         :class="{ 'is-active': isActive, 'is-open': isOpen, }"
         href="javascript:void(0)" @click="$emit('click')">
        <i class="fa" :class="[`fa-${icon}`]" v-if="icon"></i>
        <slot></slot>
        <span class="nav-right" data-agastya-ignore>
          <i class="fa" style="margin-top: 5px;" :class="[arrowClass]"></i>
        </span>
      </a>
    </span>
    <div v-show="isOpen" class="menu-submenu"><slot name="sub"></slot></div>
  </li>
</template>
<script>
export default {
  props: {
    icon: String,
    to: {
      default: '/',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    click: {
      type: Function,
    },
    router: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      hasChildren: false,
      isOpen: false,
      arrowClass: 'fa-angle-down',
      type: this.$parent.type,
    };
  },
//
//  computed: {
//    isOpen() {
//      return this.isActive;
//    },
//  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    getChildrenStatus() {
      this.$children.every((item) => {
        if (item.isOpen) this.isOpen = true;
        if (item.type === 'float') this.arrowClass = 'fa-angle-right';
        return true;
      });
    },
  },

  mounted() {
    this.isOpen = this.isActive;
    this.hasChildren = !!this.$slots.sub;
    if (this.hasChildren) {
      this.getChildrenStatus();
      //if (this.arrowClass === 'fa-angle-right') {
        this.$el.addEventListener('mouseenter', this.toggle);
        this.$el.addEventListener('mouseleave', this.toggle);
      //}
    }
  },

  beforeDestroy() {
    this.$el.removeEventListener('mouseenter', this.toggle);
    this.$el.removeEventListener('mouseleave', this.toggle);
  },
};
</script>

<style scoped>
  .nav-right {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }

  .fa {
    color: #256AB3 !important;
    font-size: 22px;
  }
  .menu-submenu {
    left: 100%;
    position: absolute;
    top: 0;
  }
  .menu-submenu /deep/ > .menus-div {}
  .menu-submenu /deep/ > .menus-div > .menu-list {
    left: initial;
  }
</style>
