<template>
    <div class="form-group" :class="{'has-error': errors.has(inputId)}">
        <label :for="inputId" v-html="deleteTags(label)"></label><label v-if="required" class="red"> *</label>
        <!--select :id="inputId" v-model="inputVal" :name="inputId" v-validate="validations"
                 :data-vv-as="deleteTags(label)" class="form-control">
            <option value="">{{t('Select...')}}</option>
            <option v-for="option in options" :value="option.key" :key="option.key">{{option.label}}</option>
        </select-->
        <div class="row no-gutters ml-5">
            <div class="col-12" v-for="(option, index) in options" :key="option.key">
                <div class="checkbox-container mt-1 pl-0 mb-0">
                    <div class="custom-control custom-checkbox checkbox-margin my-1 ml-0 py-2 pl-0 pr-3" style="border: none;">
                        <input :id="`${inputId}_${index}`" v-model="inputVal" :name="`${inputId}_${index}`" :value="option.key" v-validate="validations" type="checkbox" class="custom-control-input">
                        <label :for="`${inputId}_${index}`" class="custom-control-label">{{ option.label }}</label>
                    </div>
                </div><!-- .checkbox-container -->
            </div><!-- .col-12 -->
            <div class="col-12" v-if="includeOther"><!-- Other -->
                <div class="radio-container mt-1 pl-0 mb-0">
                    <div class="custom-control custom-checkbox custom-checkbox-other checkbox-margin my-1 ml-0 py-2 pl-0 pr-3" style="border: none;">
                        <input :id="`${inputId}_other`" v-model="inputVal" :name="`${inputId}_other`" value="Other" v-validate="validations" type="checkbox" class="custom-control-input">
                        <label :for="`${inputId}_other`" class="custom-control-label">{{ t('Other') }}</label>
                        <input type="text" v-model="other_value" v-if="inputVal.includes('Other')" v-validate="validations" class="form-control" :placeholder="t('Type other option')" />
                        <input type="text" disabled v-else class="form-control disabled" :placeholder="t('Type other option')" />
                    </div>
                </div><!-- .checkbox-container -->
            </div><!-- .col-12 -->
        </div><!-- .row -->
        <span class="md-error" v-html="errors.first(inputId)"></span>
    </div>
</template>

<script>
    export default {
        name: "checkout-form-multiple",
        props: ['value', 'label', 'options', 'required', 'inputValidations', 'uniqueId', 'includeOther', 'other_value'],
        inject: ['$validator'],
        data() {
            return {
                inputVal: [],//this.value,
                inputId: null
            }
        },
        computed: {
            _() {
                return _;
            },
            validations() {
                let validations = {};

                if (this.required) {
                    validations.required = true;
                }

                if (this.inputValidations) {
                    const dbValidations = JSON.parse(this.inputValidations);
                    validations = Object.assign(validations, dbValidations);
                }


                return validations;
            }
        },
        watch: {
            value(val) {
                console.log('* typeof val', typeof val, val);
                if(typeof val == 'string')
                    this.inputVal = val.length > 0 ? val.split(',') : [];
                else this.inputVal = val;
            },
            inputVal(val) {
                this.$emit('input', val);
            },
            other_value: {
                handler (val, old) {
                    this.$emit('updateOtherValue', val);
                },
                deep: true,
            },
        },
        created() {
            this.inputId = this.uniqueId || _.uniqueId()
            if(typeof this.value == 'string')
                this.inputVal = this.value.length > 0 ? this.value.split(',') : [];
        },
        methods:{
          deleteTags(label){
            //let result = label.replace(/<[^>]*>?/gm, '');
            let result = label.replace(/(style=")([a-zA-Z0-9:;\.\s\(\)\-\,]*)(")/gm, '')
            .replace(/<p[^>]*>/gmi, '');
            
            return result;
          },
        }
    }
</script>

<style scoped>
    .red {
        color: #D95A4B !important;
    }
    
    .custom-checkbox-other {
        align-items: center;
        display: grid;
        gap: 4px;
        grid-template-columns: auto 1fr;
        justify-self: left;
    }
</style>
