<template>
  <svg
    width="20px"
    height="13px"
    viewBox="0 0 20 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon/popular/check</title>
    <g id="FULL" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Domain/Team/Add/UsersFilters-Copy-3"
        transform="translate(-840.000000, -37.000000)"
        fill="#256AB3"
      >
        <g id="Path-7" transform="translate(838.000000, 32.000000)">
          <path
            d="M8.59270485,15.9957883 L3.49998485,11.4409695 C3.19124081,11.1648354 2.71710347,11.1912711 2.44096946,11.5000151 C2.16483545,11.8087592 2.1912711,12.2828965 2.50001515,12.5590305 L8.09048994,17.5590305 C8.37423796,17.8128086 8.8030995,17.813761 9.08797185,17.5612457 L21.4974971,6.56124565 C21.8074645,6.28648562 21.8360057,5.81247036 21.5612457,5.50250295 C21.2864856,5.19253553 20.8124704,5.16399431 20.5025029,5.43875435 L8.59270485,15.9957883 Z"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
};
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
