<template>
  <div class="d-inline-block heart-reaction-icon-container" @click.prevent="changeReaction()">
    <login-modal :corporation="corporation"
                  :modal-view="sessionModalView"
                  @change-view="sessionModalView = $event"
                  @refresh-session="stayAlive()"
                  :show-dialog="openLogin">
    </login-modal>
    <svg v-if="isSmall" :id="`heart-reaction-icon${iconUuid}`" version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" :viewBox="`0 0 ${hasLike ? '18 15.299999999999999' : '20 17.119999999999997'}`" :width="hasLike ? 18 : 20" :height="hasLike ? '15.299999999999999' : '17.119999999999997'">
      <g v-if="hasLike" transform="translate(0, 0)">
        <defs>
          <path id="path-1616459073485705" d="M1.576452108136383 1.5756983535905746 C4.247809842872193 -1.1100080311904372 6.876971898778937 0.07282353018558159 8.999999999999998 1.9792680311016084 C11.108962874944705 0.07282353018558135 13.738124930851452 -1.1239291004374474 16.423547891863617 1.5756983535905746 C18.518445540531967 3.6769671586386137 18.532510766808326 7.058444344465615 16.423547891863617 9.159713149513653 C12.894568339708648 12.360322433118716 11.052737668383138 14.01625835291469 10.082594040753138 14.892967721508745 C9.47803920067682 15.435677426163751 8.564156478152244 15.435677426163751 7.959565939532077 14.892967721508745 C6.989458010445927 14.030214754824764 5.133562112844061 12.360322433118716 1.576452108136383 9.159713149513653 C-0.5325107668083245 7.0724007463756875 -0.5184455405319698 3.690888227885624 1.576452108136383 1.5756983535905746 Z" vector-effect="non-scaling-stroke" />
        </defs>
        <g transform="translate(0, 0)">
          <path :fill="fillColor" d="M1.576452108136383 1.5756983535905746 C4.247809842872193 -1.1100080311904372 6.876971898778937 0.07282353018558159 8.999999999999998 1.9792680311016084 C11.108962874944705 0.07282353018558135 13.738124930851452 -1.1239291004374474 16.423547891863617 1.5756983535905746 C18.518445540531967 3.6769671586386137 18.532510766808326 7.058444344465615 16.423547891863617 9.159713149513653 C12.894568339708648 12.360322433118716 11.052737668383138 14.01625835291469 10.082594040753138 14.892967721508745 C9.47803920067682 15.435677426163751 8.564156478152244 15.435677426163751 7.959565939532077 14.892967721508745 C6.989458010445927 14.030214754824764 5.133562112844061 12.360322433118716 1.576452108136383 9.159713149513653 C-0.5325107668083245 7.0724007463756875 -0.5184455405319698 3.690888227885624 1.576452108136383 1.5756983535905746 Z" style="stroke-width: 0px; stroke-linecap: butt; stroke-linejoin: miter;" vector-effect="non-scaling-stroke" />
        </g>
      </g>
      <g v-else transform="translate(1, 1)">
        <defs>
          <path id="path-1616459073479693" d="M1.576452108136383 1.5571607259012739 C4.247809842872193 -1.0969491131764322 6.876971898778937 0.07196678277163357 8.999999999999998 1.9559825248533542 C11.108962874944705 0.07196678277163333 13.738124930851452 -1.1107064051381834 16.423547891863617 1.5571607259012739 C18.518445540531967 3.6337087214781594 18.532510766808326 6.975403822766019 16.423547891863617 9.051951818342905 C12.894568339708648 12.214906875082026 11.052737668383138 13.851361195821577 10.082594040753138 14.717756336549819 C9.47803920067682 15.254081221150061 8.564156478152244 15.254081221150061 7.959565939532077 14.717756336549819 C6.989458010445927 13.865153404768002 5.133562112844061 12.214906875082026 1.576452108136383 9.051951818342905 C-0.5325107668083245 6.989196031712444 -0.5184455405319698 3.6474660134399106 1.576452108136383 1.5571607259012739 Z" vector-effect="non-scaling-stroke" />
        </defs>
        <g transform="translate(0, 0)">
          <path :stroke="borderColor" d="M1.576452108136383 1.5571607259012739 C4.247809842872193 -1.0969491131764322 6.876971898778937 0.07196678277163357 8.999999999999998 1.9559825248533542 C11.108962874944705 0.07196678277163333 13.738124930851452 -1.1107064051381834 16.423547891863617 1.5571607259012739 C18.518445540531967 3.6337087214781594 18.532510766808326 6.975403822766019 16.423547891863617 9.051951818342905 C12.894568339708648 12.214906875082026 11.052737668383138 13.851361195821577 10.082594040753138 14.717756336549819 C9.47803920067682 15.254081221150061 8.564156478152244 15.254081221150061 7.959565939532077 14.717756336549819 C6.989458010445927 13.865153404768002 5.133562112844061 12.214906875082026 1.576452108136383 9.051951818342905 C-0.5325107668083245 6.989196031712444 -0.5184455405319698 3.6474660134399106 1.576452108136383 1.5571607259012739 Z" style="stroke-width: 1px; stroke-linecap: square; stroke-linejoin: miter; fill: rgba(0, 0, 0, 0);" vector-effect="non-scaling-stroke" />
        </g>
      </g>
    </svg>
    <svg v-else :id="`heart-reaction-icon${iconUuid}`" version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 26 26" height="32" width="32">
      <g transform="translate(1, 1)">
        <g v-if="hasLike" fill-rule="evenodd" fill="none" stroke-width="1" stroke="none" id="icon/popular/heart/fill_1616194624077">
          <path :fill="fillColor" id="icon_1616194624077" d="M3.75161345,5.75077595 C6.71978871,2.76665774 9.64107989,4.08091503 12,6.1991867 C14.3432921,4.08091503 17.2645833,2.75118989 20.2483865,5.75077595 C22.5760506,8.08551907 22.5916786,11.8427159 20.2483865,14.1774591 C16.3272982,17.7336916 14.2808196,19.5736204 13.2028823,20.5477419 C12.5311547,21.1507527 11.5157294,21.1507527 10.8439622,20.5477419 C9.76606446,19.5891275 7.7039579,17.7336916 3.75161345,14.1774591 C1.40832137,11.8582231 1.4239494,8.10098692 3.75161345,5.75077595 Z" vector-effect="non-scaling-stroke" />
        </g>
            <g v-else fill-rule="evenodd" fill="none" stroke-width="1" stroke="none" id="icon/popular/heart/outline_1616193952068">
          <path :fill="borderColor" id="icon_1616193952068" d="M3.75161345,5.75077595 C6.71978871,2.76665774 9.64107989,4.08091503 12,6.1991867 C14.3432921,4.08091503 17.2645833,2.75118989 20.2483865,5.75077595 C22.5760506,8.08551907 22.5916786,11.8427159 20.2483865,14.1774591 C16.3272982,17.7336916 14.2808196,19.5736204 13.2028823,20.5477419 C12.5311547,21.1507527 11.5157294,21.1507527 10.8439622,20.5477419 C9.76606446,19.5891275 7.7039579,17.7336916 3.75161345,14.1774591 C1.40832137,11.8582231 1.4239494,8.10098692 3.75161345,5.75077595 Z M4.75492377,13.0623947 C9.73341432,17.541931 10.9037934,18.5935729 11.8459581,19.4314964 C11.9477054,19.5228296 12.0991451,19.5228296 12.1971682,19.4348451 C12.4307326,19.2237752 14.0257759,17.7850561 14.6541516,17.2176223 C16.0452329,15.9614528 17.5129619,14.6333207 19.1896665,13.1148623 C20.939166,11.3717455 20.9355653,8.56459316 19.1849323,6.80863467 C17.2391166,4.85252658 15.4032405,5.14478542 13.0058869,7.31192726 L12.003337,8.21820482 L10.9977968,7.3152462 C8.58963918,5.15276003 6.75175511,4.86237895 4.81624361,6.80745269 C3.06444896,8.57742098 3.06121392,11.3836879 4.75492377,13.0623947 Z" vector-effect="non-scaling-stroke" />
        </g>
      </g>
    </svg>
    <b-tooltip v-if="hasTooltip" custom-class="tooltip1" :target="`heart-reaction-icon${iconUuid}`" placement="bottom" triggers="hover" :id="`HeartReactionInfo${iconUuid}`">
      <span v-if="hasLike">{{ t('Remove from Favorites') }}</span>
      <span v-else>{{ t('Add to Favorites') }}</span>
    </b-tooltip>
  </div>
</template>
<script>
import API from '@/api/index';
import _ from 'lodash';
import SessionManageMixin from '@/mixins/SessionManageMixin';
import LoginModal from '@/components/layout/LoginModal';

export default {
  name: "HeartReactionIcon",
  mixins: [
    SessionManageMixin,
  ],
  components: {
    LoginModal,
  },
  data() {
    return {
      pendingChangeReaction: false,
    };
  },
  props: {
    course: {
      type: Object,
    },
    isSmall: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasTooltip: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isWhite: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fillColor: {
      type: String,
      default() {
        return this.isWhite ? '#FFFFFF' : '#E01098';
      },
    },
    borderColor: {
      type: String,
      default() {
        return this.isWhite ? '#FFFFFF' : this.isSmall ? '#ABABAB' : '#9FA5AC';
      },
    }
  },
  watch: {
    isLoggedIn: function(val) {
      if(val && this.pendingChangeReaction) {
        this.pendingChangeReaction = false;
        this.doChangeReaction();
      }
    },
    'course.needsHide': function(val) {
      if(val && _.get(this.course, 'fromManager', false) && _.get(this.course, 'needsConfirmation', false)) {
        this.doChangeReaction();
      }
    },
  },
  computed: {
    iconUuid: function() {
      return this.randomUUID();
    },
    hasLike: function() {
      return _.get(this.course, 'has_like', 0);
    },
    courseIsHidden: function() {
      if(this.course.isHidden && _.get(this.course, 'fromManager', false) && _.get(this.course, 'needsConfirmation', false)) {
        this.doChangeReaction();
      }
      return this.course.isHidden;
    },
  },
  methods: {
    randomUUID: function() {
		  var d = new Date().getTime();
    	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    		var r = (d + Math.random()*16)%16 | 0;
    		d = Math.floor(d/16);
    		return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    	});
	  },
    changeReaction() {
      if(this.isLoggedIn) {
        this.doChangeReaction();
      } else {
        this.pendingChangeReaction = true;
        this.openLoginDialog();
      }
    },
    doChangeReaction() {
      if(_.get(this.course, 'fromManager', false) && !_.get(this.course, 'needsConfirmation', false)) {
        this.$set(this.course, 'needsConfirmation', true);
      } else {
        this.$set(this.course, 'has_like', !this.course.has_like);
        if(this.course.has_like) { this.showAddedFavoritesModal = true; }
        API.main.saveReaction({
            id_course: this.course.id,
            has_like: this.course.has_like
        });
        if(_.get(this.course, 'needsHide', false) && _.get(this.course, 'fromManager', false) && _.get(this.course, 'needsConfirmation', false)) {
          this.$set(this.course, 'isHidden', true);
        }
      }
    },
  },
};
</script>
<style scoped>
svg {
  cursor: pointer;
}
svg:hover,
svg:active,
svg:focus {
  outline: none;
}
</style>