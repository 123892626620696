<template>
    <div class="apprendo-table">
        <table>
            <slot></slot>
        </table>
    </div>
</template>

<script>
    export default {
        name: "apprendo-table"
    }
</script>

<style scoped>
    .apprendo-table table {
        width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
        border-collapse: collapse;
    }

    .apprendo-table table>thead {
        box-shadow: none;
    }
	
    .apprendo-table table>thead tr th {
        line-height: 22px;
        padding: 16px 24px 16px 24px;
    }

    .apprendo-table table th:first-child {
        border-radius: 4px 0 0 0;
    }

	.apprendo-table table th:last-child {
        border-radius: 0 4px 0 0;
    }
    
    .apprendo-table table>thead>th {
        border-bottom: 0;
        border-top: 0;
    }

    .apprendo-table table>tbody>tr>td {
        color: #383838;
        font-size: 16px;
        line-height: 22px;
        padding: 16px 24px;
    }

    .apprendo-table table>tbody>tr {
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #9FA5AC;
    }
    .apprendo-table table>tbody>tr:last-child {
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #9FA5AC;
}
</style>
