<template>
    <div v-if="!isApplication" class="course-flags position-absolute" :class="{ 'for-list': forList }">
        <div v-if="showComingSoon">
            <ComingSoonFlag />
        </div>
        <div v-else-if="showEnroll">
        </div>
        <div v-else-if="showWaitList">
            <WaitlistFlag />
        </div>
        <div v-else-if="showWaitingWaitList">
            <WaitingFlag />
        </div>

        <!--div class="text white"
            v-else-if="course.space > course.available && course.available <= 3 && course.available !== 0">
            <AvailableCoursesFlag :count="course.available" />
        </div-->

        <div class="text"
            v-else-if="showWaitListFull">
            <FullCourseFlag />
        </div>
    </div>
</template>
<script>
//import NewCourseFlag from '@/components/Icons/NewCourseFlag';
//import AvailableCoursesFlag from '@/components/Icons/AvailableCoursesFlag';
import FullCourseFlag from '@/components/Icons/FullCourseFlag';
//import FreeCourseFlag from '@/components/Icons/FreeCourseFlag';
import WaitlistFlag from '@/components/Icons/WaitlistFlag';
import WaitingFlag from '@/components/Icons/WaitingFlag';
import ComingSoonFlag from '@/components/Icons/ComingSoonFlag';
import EnrollBtnUtils from '@/mixins/EnrollBtnUtils';

export default {
    name: 'CourseFlags',
    mixins: [
        EnrollBtnUtils,
    ],
    components: {
        //NewCourseFlag,
        //AvailableCoursesFlag,
        FullCourseFlag,
        //FreeCourseFlag,
        WaitlistFlag,
        WaitingFlag,
        ComingSoonFlag,
    },
    props: {
        course: {
            type: Object
        },
        forList: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        showComingSoon(){
            return this.showComingSoonFunc(this.course);
        },
        showEnroll(){
            return this.showEnrollFunc(this.course);
        },
        showWaitList(){
            return this.showWaitListFunc(this.course)// && !this.course.custom_waitlist_count;
        },
        showWaitingWaitList(){
            return this.showWaitingFunc(this.course)// && !this.course.custom_waitlist_count;
        },
        showWaitListFull() {
          return this.showFullFunc(this.course);
        },
    },
}
</script>
<style scoped>
.course-flags {
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: 600;
    top: 70px;
    right: -8px;
}
.course-flags.for-list {
    top: 0;
}
</style>>
</style>