<template>
    <md-dialog :md-active.sync="openDialog" class="modal-dialog request-pay">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close close-modal pull-right"
                        style="color: #256ab3"
                        @click="openDialog = false"
                        data-dismiss="modal" aria-label="Close">
                    <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					    <g id="Checkout" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					        <g id="2.1-Multiple-Seats-/already-enrolled" transform="translate(-947.000000, -225.000000)" fill="#256AB3">
					            <g id="Group">
					                <g id="close" transform="translate(947.000000, 225.000000)">
					                    <g id="uikit/input/icon/close/out">
					                        <g id="close">
					                            <path d="M8.51721934,7.49988014 L14.7890531,1.22804634 C15.069996,0.947103476 15.069996,0.49165002 14.7890531,0.210707151 C14.5081103,-0.0702357171 14.0526568,-0.0702357171 13.7717139,0.210707151 L7.49988014,6.48254095 L1.22804634,0.211666001 C0.947103476,-0.0692768678 0.49165002,-0.0692768678 0.210707151,0.211666001 C-0.0702357171,0.492608869 -0.0702357171,0.948062325 0.210707151,1.22900519 L6.48254095,7.50083899 L0.211666001,13.7717139 C-0.0692768678,14.0526568 -0.0692768678,14.5081103 0.211666001,14.7890531 C0.35261686,14.9290451 0.535757091,15 0.719856173,15 C0.903955254,15 1.08805433,14.930004 1.22804634,14.7890531 L7.49988014,8.51721934 L13.7717139,14.7890531 C13.9126648,14.9290451 14.095805,15 14.2799041,15 C14.4640032,15 14.6481023,14.930004 14.7880943,14.7890531 C15.0690372,14.5081103 15.0690372,14.0526568 14.7880943,13.7717139 L8.51721934,7.49988014 Z" id="Shape" fill-rule="nonzero"></path>
					                        </g>
					                    </g>
					                </g>
					            </g>
					        </g>
					    </g>
					</svg>
                </button>

                <div class="row">
                    <div class="col-12 pr-0">
                        <h3 class="m-md-bottom">{{t('Before you continue:')}}</h3>

                        <ul id="requisites" v-if="!htmlField">
                            <li :key="'req_'+requisite.id" v-for="requisite in requisites">
                                <div v-if="requisite.meta === 'text'">{{requisite.text}}
                                    <input type="text" v-model="requisite.value">
                                </div>
                                <div v-else-if="requisite.meta === 'checkbox'">
                                    {{requisite.text}}
                                    <input type="checkbox" v-model="requisite.value">
                                </div>
                                <div v-else-if="requisite.meta === 'selectbox'">
                                    {{requisite.text}}
                                    <select v-model="requisite.value">
                                        <option v-for="values in requisite.options">{{values}}</option>
                                    </select>
                                </div>
                                <div v-else>{{requisite.text}} </div>
                            </li>
                        </ul>

                        <div v-if="htmlField" v-html="htmlField"></div>
                    </div>

                </div>

                <div class="row mt-4">
                    <div class="col-12">
                        <hr class="hr-footer">
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 p-0-top pb-3">
                        <button @click="openDialog = false" type="button" class="btn btn-outline-primary">{{t('No')}}</button>
                    </div>

                    <div class="col-6 pb-3">
                        <button type="button" @click="confirm" class="btn btn-primary pull-right">{{t('Yes !')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </md-dialog>
</template>

<script>
import API from '@/api/index';
import { PAGES } from '@/utils/index';

export default {
    name: "CourseEnrollModal",
    props: {
        courseId: {
            type: Number,
            required: true,
            validator: prop => typeof prop === 'number' || prop === null
        },
        requisites: {
            type: Array,
            required: true
        },
        showDialog: {
            type: Boolean,
            required: true
        }
    },
    data: function () {
        return {
            openDialog: false,
        }
    },
    watch: {
        showDialog(val) {
            this.openDialog = true;
        }
    },
    computed: {
        htmlField: function () {
            const search = _.find(this.requisites, {meta: 'html'});
            return search ? search.value : null;
        }
    },
    methods: {
        async confirm() {
            const params = this.requisites.map((req) => {
                return {
                    id_requisite:   req.id,
                    answer:         req.value,
                    id_course:      this.courseId
                }
            });

            await API.main.saveRequisites({ data: params });

            //window.location.href = PAGES.CHECKOUT + this.courseId;
            
            if(CATALOGUE_PATH=="/marketplace/index/"){
            	CATALOGUE_PATH = null
            }
            
            window.location.href = CATALOGUE_PATH? CATALOGUE_PATH +"checkout/"+ this.courseId : PAGES.CHECKOUT + this.courseId
        }
    }
}
</script>

<style scoped>
    .md-dialog {
        width: 550px;
    }

    .contacts-list {
        height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .btn.cta.invite-btn {
        border-radius: 4px;
        padding-top: 5px;
    }

    .btn.cta.invite-btn.user {
        background-color: white !important;
        color: #3676B0;
        border: 1px solid #3676B0;
    }
    .btn-outline-primary {
    border-color: #256ab3;
    color: #383838;
    background-color:#fff;
    height: 35px;
    border-radius: 4px;
    font-family: "Source Sans Pro";
        width: 82px;
}
.btn-outline-primary:hover {
	background-color: #d3e1ef;
    border-color: #256ab3;
    color: #256ab3;
}
</style>
