<template>
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 56.22999954223633 56.22999954223633"
    :width="width"
    :height="height"
  >
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        y2="-11.51"
        x2="72.48"
        y1="45.77"
        x1="5.21"
        id="grad1"
        vector-effect="non-scaling-stroke"
      >
        <stop stop-color="#fbe18a" offset="0" vector-effect="non-scaling-stroke" />
        <stop stop-color="#fcbb45" offset="0.21" vector-effect="non-scaling-stroke" />
        <stop stop-color="#f75274" offset="0.38" vector-effect="non-scaling-stroke" />
        <stop stop-color="#d53692" offset="0.52" vector-effect="non-scaling-stroke" />
        <stop stop-color="#8f39ce" offset="0.74" vector-effect="non-scaling-stroke" />
        <stop stop-color="#5b4fe9" offset="1" vector-effect="non-scaling-stroke" />
      </linearGradient>
    </defs>
    <g id="Capa_2_1599067790457">
      <g id="Design_1599067790457">
        <circle r="23.75" cy="26.88" cx="27.09" :fill="iconColor ? 'none' : '#fff'" vector-effect="non-scaling-stroke" />
        <path
          d="M27.11,54.23A27.12,27.12,0,1,1,54.23,27.11,27.14,27.14,0,0,1,27.11,54.23Zm9.15-38.12a2,2,0,1,0,2,2A2,2,0,0,0,36.26,16.11Zm-8.92,2.58a8.43,8.43,0,1,0,8.43,8.42A8.44,8.44,0,0,0,27.34,18.69Zm0,13.82a5.4,5.4,0,1,1,5.4-5.4A5.4,5.4,0,0,1,27.34,32.51Zm17-12.4A10.28,10.28,0,0,0,34.06,9.83H20.5A10.28,10.28,0,0,0,10.22,20.11V33.68A10.28,10.28,0,0,0,20.5,44H34.06A10.28,10.28,0,0,0,44.34,33.68ZM41.12,33.68a7.06,7.06,0,0,1-7.06,7.06H20.5a7.07,7.07,0,0,1-7.07-7.06V20.11a7.07,7.07,0,0,1,7.07-7.06H34.06a7.06,7.06,0,0,1,7.06,7.06Z"
          :fill="iconColor ? iconColor : 'url(#grad1)'"
          vector-effect="non-scaling-stroke"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "instagramIcon",
  props: ["height", "width", "iconColor"],
};
</script>

<style scoped>
.default {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
</style>
